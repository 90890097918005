import api from 'utils/axios';

export const getWarehouses = async (searchParams) => {
  try {
    const response = await api(`/merchants/warehouses?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getWarehouseById = async (id) => {
  try {
    const response = await api(`/merchants/warehouses/${id}`);
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getWarehouseTimeSlots = async ({
  warehouse_id,
  selected_date,
}) => {
  try {
    const response = await api(
      `/merchants/warehouses/${warehouse_id}/time_slots`,
      {
        params: { selected_date },
      }
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getQuotations = async (searchParams) => {
  try {
    const response = await api(`/merchants/quotes?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};
