import api from 'utils/axios';

export const getTransportationOrders = async (searchParams) => {
  try {
    const response = await api(
      `/merchants/transportation_orders?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const createTransportationOrder = async (organizationId, ab_trip) => {
  try {
    const response = await api.post(
      `/organizations/${organizationId}/ab_trips`,
      ab_trip
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTransportationOrder = async (organizationId, id) => {
  try {
    const response = await api(
      `/organizations/${organizationId}/ab_trips/${id}`
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const confirmTransportationOrder = async (organizationId, id) => {
  try {
    const response = await api.post(
      `/organizations/${organizationId}/ab_trips/${id}/confirm`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const cancelTransportationOrder = async (organizationId, id) => {
  try {
    const response = await api.patch(
      `/organizations/${organizationId}/ab_trips/${id}/cancel`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSkuToTransportation = async (id, ab_trip_sku) => {
  try {
    const response = await api.post(`/ab_trips/${id}/ab_trip_skus`, {
      ab_trip_sku,
    });

    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateTransportationSKU = async (
  id,
  transportationSKUId,
  ab_trip_sku
) => {
  try {
    const response = await api.patch(
      `/ab_trips/${id}/ab_trip_skus/${transportationSKUId}`,
      {
        ab_trip_sku,
      }
    );

    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const removeTransportationSKU = async (id, transportationSKUId) => {
  try {
    const response = await api.delete(
      `/ab_trips/${id}/ab_trip_skus/${transportationSKUId}`
    );

    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCarriers = async (searchParams) => {
  try {
    const response = await api(`/merchants/carrier_services?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const createTransportationJourney = async (transportation_journey) => {
  try {
    const response = await api.post(`merchants/transportation_journeys`, {
      transportation_journey,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTransportationJourney = async (id) => {
  try {
    const response = await api.delete(
      `merchants/transportation_journeys/${id}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTransportationOrder = async (id, transportation_order) => {
  try {
    const response = await api.patch(`/merchants/transportation_orders/${id}`, {
      transportation_order,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTruckTypes = async (truckSize) => {
  try {
    const response = await api(
      `/merchants/transportation_orders/truck_types?truck_size=${truckSize}`
    );

    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getABTripChargeableActivities = async ({
  organizationId,
  transportationOrderId,
  searchParams,
}) => {
  try {
    const response = await api(
      `organizations/${organizationId}/ab_trips/${transportationOrderId}/chargeable_activities?${searchParams}`
    );

    return response?.data;
  } catch (error) {
    throw error;
  }
};
