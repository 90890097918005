import * as amplitude from '@amplitude/analytics-browser';

const getDeviceIdFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('deviceId');
};

export const initAmplitude = () => {
  const deviceId = getDeviceIdFromUrl();

  amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, null, {
    ...(deviceId && { deviceId }),
    defaultTracking: {
      pageViews: false,
    },
  });
};

export const setAmplitudeUserData = ({
  email,
  role,
  company,
  companyID,
} = {}) => {
  const identifyEvent = new amplitude.Identify();

  role && identifyEvent.set('Role', role);
  company && identifyEvent.set('Organization', company);
  email && amplitude.setUserId(email);
  companyID && identifyEvent.set('Organization ID', companyID);

  amplitude.identify(identifyEvent);
};

export const pageNameMap = {
  '/sign-up': 'sign_up',
  '/sign-up/verify-otp': 'verify_otp',
  '/sign-up/verify-otp/success': 'verify_otp_success',
  '/sign-in': 'sign_in',
  '/sign-in/forgot-password': 'forgot_password',
  '/sign-in/create-password': 'create_password',
  '/sign-in/create-password/success': 'create_password_success',
  '/magic_links/new': 'magic_link_new',
  '/magic_links/validate': 'magic_link_validate',
  '/': 'overview',
  '/inbounds': 'inbounds',
  '/inbounds/:id': 'inbound_details',
  '/inbounds/create': 'request_inbound_step1',
  '/inbounds/:id/assign': 'request_inbound_step2',
  '/inbounds/:id/edit': 'edit_inbound',
  '/outbounds': 'outbounds',
  '/outbounds/:id': 'outbound_details',
  '/outbounds/create': 'request_outbound_step1',
  '/outbounds/:id/assign': 'request_outbound_step2',
  '/outbounds/:id/edit': 'edit_outbound',
  '/transportations': 'transportations',
  '/transportations/create': 'request_transportation_step1',
  '/transportations/:id': 'transportation_details',
  '/transportations/:id/summary': 'request_transportation_step2',
  '/addresses': 'addresses',
  '/addresses/create': 'create_address',
  '/addresses/:id': 'address_details',
  '/suppliers': 'suppliers',
  '/warehouses?step=1': 'onboarding_step1',
  '/warehouses?step=2': 'onboarding_step2',
  '/warehouses': 'warehouses',
  '/warehouses/search': 'warehouses_search',
  '/warehouses/quotations': 'quotations',
  '/warehouses/quote/:id': 'quote_details',
  '/warehouses/success': 'quote_approved',
  '/skus': 'skus',
  '/skus/:id': 'sku_details',
  '/skus/create': 'create_sku',
  '/skus/:id/edit': 'edit_sku',
  '/skus/:id/associated-movements': 'sku_associated_movements',
  '/reports': 'reports',
  '/sku-groups': 'sku_groups',
  '/sku-groups/:id': 'sku_group_details',
  '/sku-groups/create': 'create_sku_group',
  '/settings': 'settings',
  '/settings/profile': 'user_settings',
  '/settings/organization': 'organization_settings',
  '/settings/user-management': 'user_management',
  '/settings/push-notifications': 'notification_settings',
  '/settings/billing': 'billing_settings',
  '/lead-quote': 'lead_quote',
  '/not-found': 'not_found',
};

export const getPageName = (pathname, search) => {
  const fullPath = `${pathname}${search}`;

  // First try to find an exact match (including query string matches)
  let pageKey = Object.keys(pageNameMap).find((key) => {
    if (key.includes('?')) {
      return key === fullPath;
    }
    return key === pathname;
  });

  // If no exact match is found, then try parameter matching
  if (!pageKey) {
    pageKey = Object.keys(pageNameMap).find((key) => {
      if (key.includes('?')) {
        return false; // Skip query string routes in parameter matching
      }

      const pattern = key.replace(/:id/g, '[^/]+');

      try {
        const regex = new RegExp(`^${pattern}$`);

        return regex.test(pathname);
      } catch (error) {
        console.log('Amplitude [Page View]: Error in URL matching', error);
        return undefined;
      }
    });
  }

  return pageNameMap[pageKey];
};

export const logEvent = (eventName, eventProperties) => {
  amplitude.logEvent(eventName, eventProperties);
};

export { amplitude };
