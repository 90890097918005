import { getOutboundSKUs } from 'api/Dashboard/outbounds';
import useTable from 'hooks/useTable';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TablePage from '../TablePage';
import {
  Button,
  Center,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import PageDrawer from '../PageDrawer';
import EmptyTable from '../EmptyTable';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';

const StyledEditIcon = styled(EditIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.primary[500]};
  }
`;

const columnHelper = createColumnHelper();

const DuplicateFromOutboundsDrawer = ({ isOpen, onClose, isLoading }) => {
  const { t } = useTranslation();

  return (
    <PageDrawer
      title={t('duplicateSKUFromPreviousOutbound')}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      formId="duplicateFromOutbounds"
      isLoading={isLoading}
      size="xl"
    >
      Form
    </PageDrawer>
  );
};

const SKUsSection = ({ outboundId, status, onUpdateSuccess }) => {
  const { t } = useTranslation();

  const { isOpen, onClose } = useDisclosure();

  const fetchFn = useCallback(
    (searchParams) => getOutboundSKUs(outboundId, searchParams),
    [outboundId]
  );

  const {
    data = [],
    isLoading,
    onSearchChange,
    onSortingChange,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['outbound-skus', outboundId],
    searchKey: 'sku_merchant_sku_or_sku_system_sku_or_sku_name_cont',
    disableURLParams: true,
  });

  const isDraft = status === 'draft';

  const columns = [
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{getValue()}</Text>,
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('sku_system_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('sku_merchant_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('merchantSKU'),
    }),
    columnHelper.accessor('requested.packaging', {
      cell: ({ getValue }) => (
        <Center fontWeight={500} textTransform="capitalize">
          {t(getValue())}
        </Center>
      ),
      header: t('packaging'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('requested.quantity', {
      cell: ({ getValue }) => <Center fontWeight={500}>{getValue()}</Center>,
      header: t('quantity'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('boxes_per_pallet', {
      cell: ({ getValue }) => <Center>{getValue() || '-'}</Center>,
      header: t('casesPallets'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('units_per_box', {
      cell: ({ getValue }) => <Center>{getValue() || '-'}</Center>,
      header: t('unitsCase'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('batch_numbers', {
      cell: ({ getValue, row }) => {
        const values = getValue() ?? [];

        if (values.length === 0) {
          return '-';
        }

        return (
          <UnorderedList paddingLeft={1} display="flex" flexDirection="column">
            {values.map((value, index) => (
              <ListItem key={`${row.original.id}-batch-${index}`}>
                {value}
              </ListItem>
            ))}
          </UnorderedList>
        );
      },
      header: t('Batch/Lot Number'),
      enableSorting: false,
    }),
  ];

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        pagination={pagination}
        title={
          <SectionTitle
            title={`${t('skuCart')} (${isLoading ? '...' : data.length})`}
            hideDivider
          />
        }
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('skusPages.searchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
        tableAction={
          isDraft && (
            <Button
              size="lg"
              as={Link}
              to="assign"
              variant="solid"
              bg="primary.100"
              color="primary.500"
              leftIcon={<StyledEditIcon />}
            >
              {t('editCart')}
            </Button>
          )
        }
        emptyTable={
          <EmptyTable
            illustration={<SKUsEmptyTable />}
            title={t('noSKUsYet')}
            subtitle={`${t('selectExisting', {
              value: t('theOutbound'),
            })}`}
            noPadding
          />
        }
        showEmptyInsideBody
      />
      <DuplicateFromOutboundsDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default SKUsSection;
