import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Stack,
  Text,
  useToast,
  HStack,
  CloseButton,
  Link,
} from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';
import { Trans, useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { ReactComponent as ShareAppleIcon } from 'assets/icons/share-apple.svg';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

const isIOS = () => {
  return (
    ['iPad', 'iPhone', 'iPod'].includes(navigator?.platform) ||
    (navigator?.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const isMacOS = () => {
  return navigator?.platform.toLowerCase().includes('mac') && !isIOS();
};

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const isSafariBrowser = () => {
  const userAgent = navigator.userAgent;
  const vendor = navigator.vendor;

  return (
    /Safari/.test(userAgent) &&
    /Apple Computer/.test(vendor) &&
    !/Chrome/.test(userAgent)
  );
};

const isFirefoxBrowser = () => {
  const userAgent = navigator.userAgent;
  return /Firefox/.test(userAgent);
};

const isChromeBrowser = () => {
  const userAgent = navigator.userAgent;
  const vendor = navigator.vendor;

  return /Chrome/.test(userAgent) && /Google Inc/.test(vendor);
};

const isPWAInstalledInMacOS = () => {
  return window.navigator.standalone;
};

export const getPWADisplayMode = () => {
  if (document.referrer.startsWith('android-app://')) return 'twa';
  if (window.matchMedia('(display-mode: browser)').matches) return 'browser';
  if (window.matchMedia('(display-mode: standalone)').matches)
    return 'standalone';
  if (window.matchMedia('(display-mode: minimal-ui)').matches)
    return 'minimal-ui';
  if (window.matchMedia('(display-mode: fullscreen)').matches)
    return 'fullscreen';
  if (window.matchMedia('(display-mode: window-controls-overlay)').matches)
    return 'window-controls-overlay';

  return 'unknown';
};

const STORAGE_KEY = 'hideInstallPrompt';

const ToastContainer = ({ children, isAr, onClose, action }) => {
  const { t } = useTranslation();

  return (
    <Stack
      p={4}
      bg="primary.900"
      rounded="md"
      shadow="lg"
      position="relative"
      spacing={4}
    >
      <Stack flex={1} color="white" spacing={3}>
        {children}
      </Stack>
      <CloseButton
        variant="ghost"
        sx={{
          position: 'absolute',
          top: 2,
          color: 'white',
          left: isAr ? 1 : 'auto',
          right: isAr ? 'auto' : 1,
        }}
        onClick={onClose}
      />
      <HStack>
        {action}
        <Button
          variant="outline"
          onClick={() => {
            setLocalStorage(STORAGE_KEY, true);
            onClose();
          }}
          size="sm"
          colorScheme="white"
          color="white"
        >
          {t('dontShowAgain')}
        </Button>
      </HStack>
    </Stack>
  );
};

const createToastConfig = (render) => ({
  render,
  status: 'info',
  colorScheme: 'primary',
  duration: null,
  position: 'top',
  containerStyle: {
    minWidth: {
      base: 'calc(100% - 24px)',
      md: '500px',
    },
  },
});

export const usePWAInstall = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const toastIdRef = useRef();
  const eventListenerRef = useRef(null);

  const toast = useToast();
  const { language } = useLanguage();
  const { t } = useTranslation();

  const isAr = language === 'ar';

  const removeEventListener = useCallback(() => {
    if (eventListenerRef.current) {
      window.removeEventListener(
        'beforeinstallprompt',
        eventListenerRef.current
      );
      eventListenerRef.current = null;
    }
  }, []);

  const showIOSInstallInstructions = useCallback(() => {
    if (isPWAInstalledInMacOS()) return;

    toastIdRef.current = toast(
      createToastConfig(({ onClose }) => (
        <ToastContainer isAr={isAr} onClose={onClose}>
          <Stack spacing={0}>
            <Text fontWeight="bold">{t('installApp')}</Text>
            <Text fontSize="sm">{t('installIOSDescription')}</Text>
          </Stack>
          <Stack spacing={2}>
            <Text fontSize="sm">
              1. {t('tapShareButton')}
              <ShareAppleIcon
                style={{
                  display: 'inline',
                  marginTop: '-2px',
                  width: '18px',
                  height: '18px',
                  marginInlineStart: '4px',
                }}
              />
            </Text>
            <Text fontSize="sm">
              2. {t('scrollAndTapAddToHome')}
              <AddIcon
                sx={{
                  width: '14px',
                  height: '14px',
                  border: '1px solid white',
                  borderRadius: '2px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1px',
                  marginInlineStart: '4px',
                }}
              />
            </Text>
          </Stack>
        </ToastContainer>
      ))
    );
  }, [isAr, toast, t]);

  const showMacOSInstallInstructions = useCallback(() => {
    if (isPWAInstalledInMacOS()) return;

    toastIdRef.current = toast(
      createToastConfig(({ onClose }) => (
        <ToastContainer isAr={isAr} onClose={onClose}>
          <Stack spacing={0}>
            <Text fontWeight="bold">{t('installApp')}</Text>
            <Text fontSize="sm">{t('installAppDescription')}</Text>
          </Stack>
          <Stack spacing={2}>
            <Text fontSize="sm">
              <Trans
                i18nKey="installMacDescription"
                components={[
                  <AddIcon
                    width="16px"
                    height="16px"
                    border="1px solid white"
                    borderRadius="full"
                    justify="center"
                    align="center"
                    padding={1}
                  />,
                  <ShareAppleIcon
                    style={{
                      display: 'inline',
                      marginTop: '-2px',
                      width: '18px',
                      height: '18px',
                    }}
                  />,
                ]}
              />
            </Text>
          </Stack>
        </ToastContainer>
      ))
    );
  }, [isAr, toast, t]);

  const showFirefoxInstallInstructions = useCallback(() => {
    toastIdRef.current = toast(
      createToastConfig(({ onClose }) => (
        <ToastContainer isAr={isAr} onClose={onClose}>
          <Stack spacing={0}>
            <Text fontWeight="bold">{t('installApp')}</Text>
          </Stack>
          <Stack spacing={2}>
            <Text fontSize="sm">
              {isMobileDevice() ? (
                t('installFirefoxMobileDescription')
              ) : (
                <Trans
                  i18nKey="installFirefoxDescription"
                  components={[
                    <Link
                      href="https://addons.mozilla.org/firefox/addon/pwas-for-firefox"
                      isExternal
                      color="white"
                      textDecoration="underline"
                      _hover={{ opacity: 0.8 }}
                    />,
                  ]}
                />
              )}
            </Text>
          </Stack>
        </ToastContainer>
      ))
    );
  }, [isAr, toast, t]);

  const showChromeInstallInstructions = useCallback(
    (e) => {
      toastIdRef.current = toast(
        createToastConfig(({ onClose }) => (
          <ToastContainer
            isAr={isAr}
            onClose={onClose}
            action={
              <Button
                width="fit-content"
                size="sm"
                onClick={() => {
                  e.prompt();
                  e.userChoice.then(() => onClose());
                }}
              >
                {t('installNow')}
              </Button>
            }
          >
            <Stack spacing={0}>
              <Text fontWeight="bold">{t('installApp')}</Text>
              <Text fontSize="sm">{t('installAppDescription')}</Text>
            </Stack>
          </ToastContainer>
        ))
      );
    },
    [isAr, toast, t]
  );

  const handleBeforeInstallPrompt = useCallback(
    (e) => {
      e.preventDefault();
      removeEventListener();
      setDeferredPrompt(e);

      showChromeInstallInstructions(e);
    },
    [removeEventListener, showChromeInstallInstructions]
  );

  useEffect(() => {
    if (getLocalStorage(STORAGE_KEY) === true) return;

    if (isIOS()) {
      showIOSInstallInstructions();
      return;
    }

    if (isMacOS() && isSafariBrowser()) {
      showMacOSInstallInstructions();
      return;
    }

    if (isFirefoxBrowser()) {
      showFirefoxInstallInstructions();
      return;
    }

    eventListenerRef.current = handleBeforeInstallPrompt;
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return removeEventListener;
  }, [
    handleBeforeInstallPrompt,
    removeEventListener,
    showIOSInstallInstructions,
    showMacOSInstallInstructions,
    showFirefoxInstallInstructions,
  ]);

  return { hasPrompt: !!deferredPrompt };
};
