import {
  Stack,
  HStack,
  Text,
  Button,
  Tag,
  useMediaQuery,
  StackDivider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as TemperatureIcon } from 'assets/icons/temperature-minus.svg';
import { ReactComponent as CertificateIcon } from 'assets/icons/certificate.svg';
import styled from '@emotion/styled';
import { storageTypes } from 'pages/Dashboard/Warehouses/Warehouses';
import Carousel from 'components/shared/Carousel';
import MapPreview from './MapPreview';
import StoragePrice from './StoragePrice';
import WarehouseServicesSection from './WarehouseServicesSection';
import RatingSection from './RatingSection';

const StyledLocationIcon = styled(LocationIcon)`
  path {
    fill: #fff;
    stroke: #4a5568;
  }
`;

const WarehousePreview = ({
  warehouse,
  onQuoteInit,
  isInitQuoteLoading,
  onBookACall,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const {
    name,
    alias_name,
    address,
    condition_types,
    open_time,
    close_time,
    closed_days,
    services_highlight,
    image_urls,
    ratings,
    services,
    licenses,
  } = warehouse;

  const conditionTypes = [...new Set(condition_types)];

  const closeDaysTranslated = closed_days.map((day) => t(`weekDays.${day}`));

  const closedTime = `${open_time} - ${close_time} ${t(
    'except'
  )} ${closeDaysTranslated.join(', ')}`;

  const translatedConditionType = conditionTypes.map((type) => {
    const storageType = storageTypes[type];
    return t(storageType.label);
  });

  const location = {
    lat: warehouse.address.latitude,
    lng: warehouse.address.longitude,
  };

  return (
    <Stack spacing={6}>
      <Carousel
        slides={image_urls}
        imageProps={{
          height: isMobile ? '250px' : '300px',
          width: '100%',
          borderRadius: '16px',
        }}
      />

      <Stack spacing={6} divider={<StackDivider borderColor="gray.300" />}>
        <HStack
          gap={6}
          alignItems="flex-start"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
        >
          <Stack
            spacing={4}
            color="gray.700"
            fontSize="sm"
            width={{ base: '100%', md: '50%' }}
          >
            {!isMobile && (
              <Text fontWeight={700} fontSize="lg" color="gray.800">
                {alias_name ?? name}
              </Text>
            )}

            <HStack spacing={2}>
              <StyledLocationIcon width="24px" height="24px" />
              <Text>{isAR ? address.city.nameLocale : address.city.name}</Text>
            </HStack>

            <HStack spacing={2}>
              <TemperatureIcon width="24px" height="24px" />
              <Text>{translatedConditionType.join(', ')}</Text>
            </HStack>

            <HStack spacing={2}>
              <ClockIcon width="24px" height="24px" />
              <Text as="span">{closedTime}</Text>
            </HStack>

            <RatingSection
              rating={ratings?.average_inbound_rating}
              label={t('avgInboundRating')}
            />

            <RatingSection
              rating={ratings?.average_outbound_rating}
              label={t('avgOutboundRating')}
            />

            <Stack spacing={3}>
              <HStack
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <HStack spacing={2}>
                  <CertificateIcon width="24px" height="24px" />
                  <Text as="span" textTransform="capitalize">
                    {t('licenses')}
                  </Text>
                </HStack>
                {!licenses.length && (
                  <Text as="span" textTransform="capitalize">
                    {t('noSFDALicense')}
                  </Text>
                )}
              </HStack>

              {licenses.length && (
                <HStack spacing={3} wrap="wrap">
                  {licenses.map((license) => (
                    <Tag
                      size="lg"
                      borderRadius="lg"
                      fontSize="xs"
                      fontWeight={600}
                      key={license.id}
                    >
                      {t(`warehouseLicenses.${license.name}`)}
                    </Tag>
                  ))}
                </HStack>
              )}
            </Stack>
          </Stack>

          <Stack width={{ base: '100%', md: '50%' }}>
            <MapPreview location={location} key={warehouse.id} />
          </Stack>
        </HStack>

        <StoragePrice services={services} onBookACall={onBookACall} />

        <Stack spacing={4}>
          <WarehouseServicesSection services_highlight={services_highlight} />
        </Stack>
      </Stack>

      {!isMobile && (
        <Button
          colorScheme="primary"
          textTransform="capitalize"
          fontWeight={600}
          size="lg"
          onClick={() => onQuoteInit(warehouse.id)}
          isLoading={isInitQuoteLoading}
        >
          {t('getInstantQuote')}
        </Button>
      )}
    </Stack>
  );
};

export default WarehousePreview;
