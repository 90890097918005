import { HStack, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as StarTagIcon } from 'assets/icons/star-tag.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star-filled.svg';

const RatingSection = ({ rating, label }) => {
  return (
    <HStack spacing={2}>
      <StarTagIcon width="24px" height="24px" />
      <Flex justifyContent="space-between" width="full">
        <Text as="span">{label}</Text>
        <HStack>
          <StarIcon width="16px" height="16px" />
          <Text as="span" color="gray.900" fontWeight={500}>
            {rating || '-'}
          </Text>
        </HStack>
      </Flex>
    </HStack>
  );
};

export default RatingSection;
