import { Box, Flex, Hide, Show, Spinner, Stack } from '@chakra-ui/react';
import {
  getInboundById,
  requestNewInbound,
  updateInbound,
} from 'api/Dashboard/inbounds';
import BackToListButton from 'components/Dashboard/BackToListButton';
import InboundForm from 'components/Dashboard/Inbounds/InboundForm';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { inboundTypeEnum } from 'constants/dashboard';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { adjustToUTC, utcStringToDate } from 'utils/date';
import { handleNotFoundPage } from 'utils/notFoundPage';
import { mapSelectListToFormOptions } from 'utils/select';

const InboundEdit = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data = {},
    refetch,
    isLoading: isRequestNewInboundLoading,
  } = useQuery(['request-new-inbound'], requestNewInbound);

  const {
    data: { data: inboundDetails = {} } = {},
    isLoading: isInboundDetailsLoading,
  } = useQuery(['inbound-details', id], () => getInboundById(id), {
    onError: handleNotFoundPage,
    refetchOnMount: true,
    cacheTime: 0,
  });

  const { mutate: updateInboundMutation, isLoading: isUpdateInboundLoading } =
    useMutation((inbound) => updateInbound(id, inbound), {
      onSuccess: (success) => {
        navigate(`/inbounds/${id}/assign`);
      },
    });

  if (isRequestNewInboundLoading || isInboundDetailsLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const status = inboundDetails.status;
  const isDraft = status === 'draft';

  const inboundTypeOptions = data.select_options?.inbound_types.map(
    ([label, value]) => {
      const labelKey = Object.keys(inboundTypeEnum).find(
        (key) => inboundTypeEnum[key] === value
      );

      return {
        label: t(`orderCarrier.${labelKey}`),
        value,
      };
    }
  );

  const originOptions = mapSelectListToFormOptions(
    data.select_options?.origins
  );

  const warehouseOptions = data.select_options?.warehouses.map((warehouse) => ({
    label: warehouse.name,
    value: warehouse.id,
    id: warehouse.id,
  }));

  const supplierOptions = mapSelectListToFormOptions(
    data.select_options?.suppliers
  );

  const defaultValues = {
    carrier_id: inboundDetails.carrier_id,
    origin_id: inboundDetails.origin?.id,
    warehouse_id: inboundDetails.warehouse_id,
    schedule_at: utcStringToDate(inboundDetails.schedule_at),
    supplier_id: inboundDetails.supplier_id,
    time_of_pickup: inboundDetails.time_of_pickup,
  };

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      schedule_at: adjustToUTC(data.schedule_at),
      time_of_pickup: data.time_of_pickup.value,
      urgent: data.time_of_pickup.urgent,
    };

    updateInboundMutation(payload);
  };

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton title={t('inboundsList')} url="/inbounds" />
          </Hide>
        </Flex>
      </Box>

      <InboundForm
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        inboundTypeOptions={inboundTypeOptions}
        originOptions={originOptions}
        warehouseOptions={warehouseOptions}
        supplierOptions={supplierOptions}
        isLoading={isUpdateInboundLoading}
        onAddNewSuccess={() => refetch()}
        isDisabled={!isDraft}
        isEdit
      />
    </Stack>
  );
};

export default InboundEdit;
