import { Flex, Stack, Text, Box } from '@chakra-ui/react';

export const InventoryTypeLabel = ({
  label,
  description = [],
  illustration,
}) => {
  return (
    <Flex
      width="full"
      paddingBlock={4}
      paddingInline={2}
      alignItems="center"
      flexDirection={{ base: 'column-reverse', md: 'row' }}
    >
      <Stack flex={1} spacing={2}>
        <Text fontSize="lg" fontWeight={600} textTransform="capitalize">
          {label}
        </Text>
        {description.map(({ title, content }, index) => (
          <Text color="gray.900" fontSize="sm" fontWeight={400} key={index}>
            <Text as="span" fontWeight={600} marginInlineEnd={1}>
              {title}:
            </Text>
            {content}
          </Text>
        ))}
      </Stack>

      <Box maxWidth="200px">{illustration}</Box>
    </Flex>
  );
};
