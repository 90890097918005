import { Tab as ChakraTab } from '@chakra-ui/react';

const Tab = ({ children }) => (
  <ChakraTab
    sx={{
      borderRadius: 'lg',
      paddingY: 2,
      paddingX: { base: 2, md: 5 },
      color: 'gray.600',
      fontWeight: 500,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    }}
    _selected={{
      color: 'primary.500',
      bg: 'primary.50',
      fontWeight: 600,
      svg: {
        path: {
          stroke: 'primary.500',
        },
      },
    }}
  >
    {children}
  </ChakraTab>
);

export default Tab;
