import debounce from 'lodash.debounce';
import { useRef, useState } from 'react';
import { Select } from 'chakra-react-select';
import { useQuery } from 'react-query';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { inputSizes } from 'constants/theme';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import OptionalIndicator from 'components/shared/OptionalIndicator';
import useLanguage from 'hooks/useLanguage';

const AsyncSelect = ({
  performSearchRequest,
  entityKey,
  error,
  required,
  label,
  onChange,
  defaultValue,
  value,
  isMulti,
  size = 'md',
  placeholder,
  searchKey = 'name_cont',
  arLabelKey,
  disabled,
  isClearable = true,
  enableFetch,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');

  const {
    refetch,
    isLoading,
    isRefetching,
    error: fetchError,
    data = {},
  } = useQuery(
    // searchText ? [entityKey, searchText] : [entityKey],
    Array.isArray(entityKey) ? [...entityKey] : [entityKey],
    async () => {
      const urlSearchParams = new URLSearchParams({
        [`q[${searchKey}]`]: searchText,
      }).toString();

      return await performSearchRequest(urlSearchParams);
    },
    {
      refetchOnMount: true,
      enabled: !disabled || enableFetch,
    }
  );

  useDidUpdateEffect(() => {
    refetch();
  }, [searchText]);

  const options =
    data.data?.map((option) => ({
      value: option.id,
      label: isAr && arLabelKey ? option[arLabelKey] : option.name,
    })) ?? [];

  const handleSearchDebounced = useRef(
    debounce((searchText) => setSearchText(searchText), 300)
  ).current;

  const handleInputChange = (inputText, meta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setInputText(inputText);
      handleSearchDebounced(inputText);
    }
  };

  // TODO: check better approach
  const currentValue =
    (isMulti
      ? options?.filter((option) => value?.some((val) => val === option.value))
      : options?.find((option) => option.value === value)) ?? '';

  return (
    <FormControl isInvalid={error} isRequired={!!required}>
      {label && (
        <FormLabel
          textTransform="capitalize"
          color="gray.700"
          requiredIndicator={false}
          optionalIndicator={<OptionalIndicator />}
        >
          {label}
        </FormLabel>
      )}
      {fetchError && 'An error has occurred'}

      {isLoading ? (
        <Spinner color="primary.500" />
      ) : (
        <Select
          options={options}
          isClearable={isClearable}
          inputValue={inputText}
          onInputChange={handleInputChange}
          onChange={onChange}
          isLoading={!!searchText && isRefetching}
          filterOption={null}
          styles={{
            menuPortal: (baseStyles) => ({
              ...baseStyles,
              zIndex: 1401,
            }),
          }}
          chakraStyles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              background: 'gray.50',
              textTransform: 'capitalize',
              borderRadius: 'xl',
              fontSize: ['lg', 'md'].includes(size) ? 'md' : 'sm',
              minHeight: inputSizes[size],
              height: isMulti ? 'auto' : inputSizes[size],
              borderWidth: '1px',
              borderColor: 'gray.200',
              boxShadow: 'none',
              _invalid: {
                boxShadow: 'none',
                borderColor: 'red.500',
              },
            }),
            input: (baseStyles, state) => ({
              ...baseStyles,
              boxShadow: 'none !important',
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              fontSize: 'sm',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              marginTop: 1,
              zIndex: 1401,
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              display: 'flex',
              flexDirection: 'column',
              paddingInline: 2,
              gap: 2,
              borderRadius: 'xl',
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 'md',
              textTransform: 'capitalize',
              fontSize: 'sm',
              _selected: {
                backgroundColor: 'primary.500',
                color: 'white',
              },
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              background: 'primary.500',
            }),
            multiValueLabel: (baseStyles) => ({
              ...baseStyles,
              color: 'white',
            }),
            multiValueRemove: (baseStyles) => ({
              ...baseStyles,
              color: 'white',
            }),
          }}
          isMulti={isMulti}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={currentValue}
          isDisabled={disabled}
        />
      )}
      <FormErrorMessage>{t(error?.message)}</FormErrorMessage>
    </FormControl>
  );
};

export default AsyncSelect;
