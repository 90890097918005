import { memo, useCallback, useState } from 'react';
import {
  GoogleMap,
  MarkerF,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useMapLoader from 'hooks/useMapLoader';
import { riyadhLocation } from 'constants/locations';
import OptionalIndicator from '../OptionalIndicator';

const getCityFromAddressComponents = (addressComponents) => {
  return addressComponents.find((address_component) =>
    address_component.types.some(
      (type) => type === 'locality' || type === 'administrative_area_level_2'
    )
  );
};

const getCountryFromAddressComponents = (addressComponents) => {
  return addressComponents.find((address_component) =>
    address_component.types.some((type) => type === 'country')
  );
};

const Map = ({
  onAddressChange,
  defaultValue,
  error,
  required,
  label,
  isFlipped,
  inputSize,
  mapHeight = '250px',
  watchAddress,
  disabled,
  hideSearch,
  showCity,
}) => {
  const { t } = useTranslation();

  const { isLoaded } = useMapLoader();

  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState();
  const [marker, setMarker] = useState();
  const [placesService, setPlacesService] = useState();

  const defaultLocation = defaultValue?.location ?? riyadhLocation;

  const [searchTerm, setSearchTerm] = useState(defaultValue?.name ?? '');

  const onLoad = useCallback((map) => {
    setMap(map);
    setPlacesService(new window.google.maps.places.PlacesService(map));
  }, []);

  const clearMap = () => {
    if (searchBox && map && marker) {
      setSearchTerm('');
      searchBox.setBounds(map.getBounds());
      marker.setPosition(riyadhLocation);
      map.panTo(riyadhLocation);
    }
  };

  useDidUpdateEffect(() => {
    if (watchAddress) {
      return;
    }
    clearMap();
  }, [watchAddress]);

  const handleSearch = () => {
    const places = searchBox?.getPlaces();

    if (!places || places.length === 0) {
      return;
    }

    const place = places[0];
    const addressComponents = place.address_components;

    if (!addressComponents) {
      return;
    }
    const city = getCityFromAddressComponents(addressComponents);
    const country = getCountryFromAddressComponents(addressComponents);

    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    onAddressChange({
      city,
      country,
      location: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
      name: place.formatted_address,
      url: place.url,
    });

    setSearchTerm(place.formatted_address);

    marker.setPosition(latLng);
    map.panTo(latLng);
  };

  const handleMapClick = (mapMouseEvent) => {
    if (disabled) {
      return;
    }

    const latLng = {
      lat: mapMouseEvent.latLng.lat(),
      lng: mapMouseEvent.latLng.lng(),
    };

    try {
      placesService.textSearch(
        {
          query: `${latLng.lat},${latLng.lng}`,
          location: latLng,
          radius: 1,
          language: 'en',
        },
        (results, status) => {
          if (status !== 'OK' || !results[0]) {
            window.alert('No results found');
            return;
          }

          placesService.getDetails(
            {
              placeId: results[0].place_id,
              fields: [
                'name',
                'address_components',
                'formatted_address',
                'url',
              ],
            },
            (place) => {
              if (!place) {
                return;
              }

              const addressComponents = place.address_components;
              const city = getCityFromAddressComponents(addressComponents);
              const country =
                getCountryFromAddressComponents(addressComponents);

              setSearchTerm(place.formatted_address);
              onAddressChange({
                city,
                country,
                location: latLng,
                name: place.formatted_address,
                url: place.url,
              });

              searchBox.setBounds(map.getBounds());
              marker.setPosition(latLng);
              map.panTo(latLng);
            }
          );
        }
      );
    } catch (error) {
      window.alert('Places search failed due to: ' + error);
    }
  };

  if (!isLoaded) {
    return null;
  }

  const containerStyle = {
    width: '100%',
    height: mapHeight,
    borderRadius: '8px',
  };

  return (
    <Flex flexDirection={isFlipped ? 'column-reverse' : 'column'} gap={5}>
      {!hideSearch && (
        <FormControl
          isInvalid={error}
          isRequired={required}
          isDisabled={disabled}
        >
          <FormLabel
            textTransform="capitalize"
            requiredIndicator={false}
            optionalIndicator={<OptionalIndicator />}
          >
            {label}
          </FormLabel>
          <StandaloneSearchBox
            onPlacesChanged={handleSearch}
            onLoad={(ref) => {
              setSearchBox(ref);
            }}
            onUnmount={() => {
              searchBox?.setBounds(null);
              setSearchBox(null);
            }}
          >
            <Input
              placeholder={t('searchGoogleMaps')}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              size={inputSize}
            />
          </StandaloneSearchBox>
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
      )}

      {showCity && (
        <FormControl isInvalid={error}>
          <FormLabel textTransform="capitalize">{t('city')}</FormLabel>
          <Input
            defaultValue={watchAddress?.city_name}
            size={inputSize}
            disabled
          />
        </FormControl>
      )}

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultLocation}
        zoom={12}
        onLoad={onLoad}
        onClick={handleMapClick}
        onUnmount={(map) => {
          setMap(null);
        }}
      >
        <MarkerF
          position={defaultLocation}
          onLoad={(ref) => {
            setMarker(ref);
          }}
          onUnmount={() => {
            marker?.setMap(null);
            setMarker(null);
          }}
        />
      </GoogleMap>
    </Flex>
  );
};

export default memo(Map);
