import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Link,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { usePaymentDue } from 'context/PaymentDueContext';
import { getLocalStorage } from 'utils/localStorage';

const UnpaidInvoiceAlert = () => {
  const { t } = useTranslation();
  const { isPaymentDueForAlert } = usePaymentDue();

  if (!isPaymentDueForAlert) {
    return null;
  }

  const isAdmin = getLocalStorage('user')?.role === 'admin';

  const alertDescription = isAdmin
    ? 'pleaseReviewAndSettleYourOutstandingBalance'
    : 'pleasePromptYourAdminUser';

  return (
    <Alert status="error" alignItems="start" size="sm" color="gray.700">
      <AlertIcon />
      <Box>
        <AlertTitle textTransform="capitalize">
          {t('unpaidInvoices')}
        </AlertTitle>
        <AlertDescription>
          {t('toMaintainAccessToYourAccount')}{' '}
          <Trans
            i18nKey={alertDescription}
            components={[
              <Link
                as={ReactRouterLink}
                to="/settings/billing"
                color="blue.600"
                textDecoration="underline"
                fontWeight={700}
                textTransform="capitalize"
              >
                {t('settingsPage')}
              </Link>,
            ]}
          />
        </AlertDescription>
      </Box>
    </Alert>
  );
};

export default UnpaidInvoiceAlert;
