import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import api from 'utils/axios';

const SCRIPT_REGEX_MAIN = /^.*\/(main.*\.js).*$/;

const getCurrentScript = () => {
  const scriptTags = document.getElementsByTagName('script');

  for (const script of scriptTags) {
    const match = SCRIPT_REGEX_MAIN.exec(script.src);
    if (match?.[1]) return match[1];
  }

  return undefined;
};

const fetchDeploymentHtml = async (deploymentUrl) => {
  const response = await api(deploymentUrl, {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  });

  return response?.data;
};

/**
 * A custom hook that polls a deployment URL to check for new versions of the application.
 * It compares the current main script filename with the one from the deployment URL to
 * determine if a new version is available.
 *
 * @param {Object} options - The configuration options
 * @param {string} options.deploymentUrl - The URL to poll for checking new deployments
 * @param {number} [options.pollingInterval=300000] - The interval in milliseconds between polls (defaults to 5 minutes)
 *
 * @returns {Object} An object containing:
 *   - isNewVersionAvailable: boolean indicating if a new version of the application is available
 *
 * @example
 * const { isNewVersionAvailable } = useAppVersionPoller({
 *   deploymentUrl: 'https://my-app.com',
 *   pollingInterval: 300000
 * });
 */
export const useAppVersionPoller = ({
  deploymentUrl,
  pollingInterval = 5 * 60 * 1000, // 5 minutes
}) => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  const { data: loadedText } = useQuery({
    queryKey: ['deploymentHtml', deploymentUrl],
    queryFn: () => fetchDeploymentHtml(deploymentUrl),
    refetchInterval: pollingInterval,
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (!loadedText) return;

    const match = SCRIPT_REGEX_MAIN.exec(loadedText);
    const remoteMainScript = match?.[1];

    if (!remoteMainScript) {
      console.warn('Could not find main script in remote index.html');
      return;
    }

    const currentMainScript = getCurrentScript();

    if (!currentMainScript) {
      console.warn('Could not find main script in current document');
      return;
    }

    const hasNewVersion = currentMainScript !== remoteMainScript;
    setIsNewVersionAvailable(hasNewVersion);

    if (hasNewVersion) {
      console.info('New version available', {
        current: currentMainScript,
        remote: remoteMainScript,
      });
    }
  }, [loadedText]);

  return { isNewVersionAvailable };
};
