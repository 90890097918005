import {
  Stack,
  Text,
  FormControl,
  Button,
  Textarea,
  Avatar,
  Flex,
  Box,
} from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import { getLocalStorage } from 'utils/localStorage';

const Comments = ({ comments, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const user = getLocalStorage('user');
  const { register, handleSubmit, reset, watch } = useForm();

  const handleCommentSubmit = (comment) => {
    onSubmit(comment);
    reset();
  };

  const commentValue = watch('comment');

  return (
    <Stack spacing={6}>
      {comments.length && (
        <Stack spacing={4} maxHeight="250px" overflowY="auto">
          {comments.map((comment, index) => (
            <Flex
              key={index}
              p={5}
              paddingBottom={6}
              gap={4}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="xl"
            >
              <Avatar
                name={comment.author}
                src={comment.avatar_url}
                bg="primary.700"
                color="white"
                boxSize="40px"
              />
              <Box flex={1} fontSize="sm">
                <Flex justifyContent="space-between">
                  <Text fontWeight={600}>{comment.author}</Text>
                  <Text fontWeight={500} fontSize="xs" color="gray.600">
                    {formatDate(comment.created_at)}
                  </Text>
                </Flex>
                <Text mt={2} fontWeight={500} color="gray.600">
                  {comment.body}
                </Text>
              </Box>
            </Flex>
          ))}
        </Stack>
      )}
      <form onSubmit={handleSubmit(handleCommentSubmit)} noValidate>
        <Box position="relative">
          <Avatar
            name={user?.name}
            src={user?.avatar}
            bg="primary.700"
            color="white"
            style={{
              position: 'absolute',
              top: '20px',
              left: isRTL ? 'auto' : '20px',
              right: isRTL ? '20px' : 'auto',
              zIndex: 2,
            }}
            boxSize="40px"
          />
          <FormControl>
            <Textarea
              placeholder={t('writeComment')}
              minHeight="158px"
              borderRadius="xl"
              paddingInlineStart="74px"
              paddingInlineEnd={28}
              paddingTop={6}
              paddingBottom={4}
              fontSize="sm"
              bg="gray.50"
              isDisabled={isLoading}
              {...register('comment')}
            />
          </FormControl>
          <Flex>
            <Button
              type="submit"
              colorScheme="primary"
              style={{
                position: 'absolute',
                bottom: '16px',
                right: isRTL ? 'auto' : '16px',
                left: isRTL ? '16px' : 'auto',
                zIndex: 1,
              }}
              isLoading={isLoading}
              isDisabled={!commentValue}
            >
              {t('publish')}
            </Button>
          </Flex>
        </Box>
      </form>
    </Stack>
  );
};

export default Comments;
