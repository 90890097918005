import {
  Button,
  Flex,
  Stack,
  Text,
  Tabs,
  TabList,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as WarehouseIcon } from 'assets/icons/warehouse.svg';
import { ReactComponent as QuotationsIcon } from 'assets/icons/bill.svg';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Tab from 'components/Dashboard/Warehouses/Tab';
import QuoteFormDrawer from 'components/Dashboard/Warehouses/QuoteFormDrawer';

const WhiteQuotationsIcon = styled(QuotationsIcon)`
  path {
    stroke: #fff;
  }
`;

const getActiveTabIndex = (tabs, path) =>
  tabs.findIndex((tab) => `/warehouses${tab.path}` === path);

const WarehousesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryClient = useQueryClient();

  const [showRequestQuoteButton, setShowRequestQuoteButton] = useState(true);

  const onShowRequestQuoteButton = (show) => {
    setShowRequestQuoteButton(show);
  };

  const RequestQuoteButton = ({ fullWidth }) => {
    return (
      <Button
        leftIcon={<WhiteQuotationsIcon />}
        colorScheme="primary"
        textTransform="capitalize"
        size="lg"
        width={fullWidth && 'full'}
        onClick={onOpen}
      >
        {t('requestQuote')}
      </Button>
    );
  };

  const tabs = [
    {
      title: 'warehouses',
      icon: <WarehouseIcon />,
      path: '',
    },
    {
      title: 'quotations',
      icon: <QuotationsIcon />,
      path: '/quotations',
    },
  ];

  const activeTabIndex = getActiveTabIndex(tabs, pathname);

  const handleSearchSubmit = (data) => {
    const city = queryClient
      .getQueriesData(['quote-cities'])[0]?.[1]
      ?.find((quoteCity) => quoteCity.id === data.city_id);

    navigate('search', {
      state: {
        warehousesSearch: { ...data, city: city },
      },
    });
  };

  return (
    <>
      <Stack spacing={{ base: 4, md: 6 }} height="full">
        <Tabs
          variant="unstyled"
          isLazy
          isFitted={{ base: true, md: false }}
          index={activeTabIndex}
          height="full"
        >
          <Flex
            justifyContent="space-between"
            flexFlow={{ md: 'row', base: 'column-reverse' }}
            gap={3}
            mb={4}
          >
            <TabList
              sx={{
                background: 'white',
                width: { base: 'full', md: 'fit-content' },
                padding: 2,
                borderRadius: 'xl',
              }}
            >
              {tabs.map(({ icon, title, path }, index) => (
                <Tab key={index}>
                  <HStack spacing={2} as={Link} to={`/warehouses${path}`}>
                    {icon}
                    <Text as="span">{t(title)}</Text>
                  </HStack>
                </Tab>
              ))}
            </TabList>
            {showRequestQuoteButton && <RequestQuoteButton />}
          </Flex>

          <Outlet
            context={{ onRequestQuote: onOpen, onShowRequestQuoteButton }}
          />
        </Tabs>
      </Stack>

      <QuoteFormDrawer
        onSubmit={handleSearchSubmit}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default WarehousesPage;
