import {
  Stack,
  Text,
  List,
  ListItem,
  ListIcon,
  StackDivider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@chakra-ui/react';
import { ReactComponent as CheckCircle } from 'assets/icons/checkCircle.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/closeCircle.svg';
import { isNumber } from 'utils/number';

const ServicesHighlightList = ({ list }) => {
  return (
    <List spacing={3} width={{ base: '100%', md: '50%' }} color="gray.700">
      {list.map(({ content, isChecked }, index) => (
        <ListItem key={index} display="flex" alignItems="center">
          <ListIcon as={isChecked ? CheckCircle : CloseCircle} boxSize={6} />
          <Text fontSize="sm">{content}</Text>
        </ListItem>
      ))}
    </List>
  );
};

const WarehouseServicesSection = ({ services_highlight }) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const servicesHighlight = Object.keys(services_highlight ?? {})
    .map((key) => {
      const value = services_highlight[key];

      return {
        content: t(`servicesHighlight.${key}`, { value }),
        isChecked: isNumber(value) ? true : value,
      };
    })
    .sort((a, b) => (b.isChecked ? 1 : 0) - (a.isChecked ? 1 : 0));

  const halfIndex = Math.ceil(servicesHighlight.length / 2);
  const firstHalf = servicesHighlight.slice(0, halfIndex);
  const secondHalf = servicesHighlight.slice(halfIndex);

  return (
    <>
      <Text
        textTransform="capitalize"
        fontWeight={500}
        fontSize="lg"
        color="gray.800"
      >
        {t('warehouseServices')}
      </Text>

      <Stack
        divider={
          !isMobile && <StackDivider borderColor="gray.300" borderWidth="2px" />
        }
        spacing={{ base: 3, md: 8 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <ServicesHighlightList list={firstHalf} />
        <ServicesHighlightList list={secondHalf} />
      </Stack>
    </>
  );
};

export default WarehouseServicesSection;
