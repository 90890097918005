import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  SimpleGrid,
  useRadioGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import RadioCard from './RadioCard';
import OptionalIndicator from 'components/shared/OptionalIndicator';

const RadioCardGroup = ({
  options,
  defaultValue,
  required,
  label,
  columns = 3,
  name,
  register,
  errors,
  withCheckMark,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
  });

  const group = getRootProps();

  const nestedName = name.split('.');

  const error =
    nestedName.length > 1
      ? errors?.[nestedName[0]]?.[nestedName[1]]
      : errors?.[name];

  return (
    <FormControl as="fieldset" isInvalid={error} isRequired={required}>
      <FormLabel
        as="legend"
        color="gray.700"
        requiredIndicator={false}
        optionalIndicator={<OptionalIndicator />}
      >
        {label}
      </FormLabel>
      <SimpleGrid
        {...group}
        columns={{ md: columns, sm: columns === 1 ? 1 : 2 }}
        spacing={3}
      >
        {options.map(({ value, label }, index) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard
              key={index}
              {...radio}
              register={register}
              required={required}
              withCheckMark={withCheckMark}
            >
              {label}
            </RadioCard>
          );
        })}
      </SimpleGrid>
      <FormErrorMessage>{t(error?.message)}</FormErrorMessage>
    </FormControl>
  );
};

export default RadioCardGroup;
