import {
  Button,
  Flex,
  Stack,
  Text,
  HStack,
  useDisclosure,
  Tag,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  StackDivider,
  Grid,
  GridItem,
  Spinner,
} from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { adjustToUTC, formatDate } from 'utils/date';
import BackToListButton from '../BackToListButton';
import SuggestedWarehousesDesktopItem from './SuggestedWarehousesDesktopItem';
import WarehousePreview from './WarehousePreview';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { useMutation, useQueryClient } from 'react-query';
import { initQuote, requestQuote } from 'api/onboarding';
import { ReactComponent as WarehouseEmptyTable } from 'assets/images/warehouses-empty-table.svg';
import EmptyTable from '../EmptyTable';
import QuoteFormDrawer from './QuoteFormDrawer';
import { logEvent } from 'utils/amplitude';

const getQuantityType = (warehousesSearch) =>
  !!+warehousesSearch.number_of_pallets ? 'pallet' : 'sqM';

export const mapToRequestQuotePayload = (data) => {
  const filteredSfdaLicenseType = data.sfda_license_type?.filter(
    (type) => type.value
  );
  return {
    ...data,
    date: adjustToUTC(data.date),
    ending_date: adjustToUTC(data.ending_date),
    warehouse_type: data.warehouse_type.map((type) => type.value),
    sfda_license_type: filteredSfdaLicenseType?.length
      ? filteredSfdaLicenseType.map((type) => type.value)
      : undefined,
  };
};

const SuggestedWarehouses = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });
  const queryClient = useQueryClient();

  const {
    isOpen: isPreviewModalOpen,
    onOpen: onPreviewModalOpen,
    onClose: onPreviewModalClose,
  } = useDisclosure();

  const {
    isOpen: isSearchFormOpen,
    onOpen: onSearchFormOpen,
    onClose: onSearchFormClose,
  } = useDisclosure();

  const warehousesSearchDefaultValue = useMemo(
    () => locationState?.warehousesSearch ?? {},
    [locationState?.warehousesSearch]
  );

  const [warehousesSearch, setWarehousesSearch] = useState(
    warehousesSearchDefaultValue
  );

  const [selectedWarehouse, setSelectedWarehouse] = useState();

  const {
    mutate: requestQuoteMutation,
    isLoading: isRequestQuoteLoading,
    data: { data: warehouses } = {},
    error,
  } = useMutation(requestQuote, {
    onSettled: () => {
      onPreviewModalClose();
    },
    onSuccess: (data) => {
      setSelectedWarehouse(isMobile ? null : data?.data?.[0]);
    },
  });

  const handleQuoteSearch = useCallback(
    (data) => {
      const payload = mapToRequestQuotePayload(data);
      requestQuoteMutation(payload);
    },
    [requestQuoteMutation]
  );

  useEffect(() => {
    if (Object.keys(warehousesSearch).length) {
      handleQuoteSearch(warehousesSearch);
    } else {
      navigate('/warehouses');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: initQuoteMutation, isLoading: isInitQuoteLoading } =
    useMutation(initQuote, {
      onSuccess: (data) => {
        navigate(`/warehouses/quote/${data.id}`, {
          state: { warehousesSearch },
        });
      },
    });

  const noWarehousesFound = error?.response?.status === 404;
  const getEventProps = () => {
    const eventProps = {
      ...mapToRequestQuotePayload(warehousesSearch),
      warehouse_id: selectedWarehouse.id,
    };

    if (eventProps.city) {
      delete eventProps.city;
    }

    return eventProps;
  };

  const logGetInstantQuote = () => {
    const eventProps = getEventProps();

    logEvent('warehouses_getInstantQuoteClicked', {
      ...eventProps,
    });
  };

  const logBookACall = () => {
    const eventProps = getEventProps();

    logEvent('warehouses_bookACallClicked', {
      ...eventProps,
    });
  };

  const handleInitQuote = () => {
    logGetInstantQuote();

    initQuoteMutation({
      warehouseId: selectedWarehouse.id,
      businessNeeds: {
        ...warehousesSearch,
        warehouse_type: warehousesSearch.warehouse_type.map(
          (type) => type.value
        ),
        sfda_license_type: warehousesSearch.sfda_license_type?.map(
          (type) => type.value
        ),
      },
    });
  };

  const quantityType = getQuantityType(warehousesSearch);

  const quantity =
    quantityType === 'pallet'
      ? warehousesSearch.number_of_pallets
      : warehousesSearch.number_of_sqm;

  const warehouseTypeOptions = [
    {
      label: t('dry'),
      value: 'dry',
    },
    {
      label: t('tempControlled'),
      value: 'air_conditioned',
    },
    {
      label: t('chilled'),
      value: 'refrigerated',
    },
    {
      label: t('frozen'),
      value: 'frozen',
    },
  ];

  const warehouseSearchSections = [
    {
      label: t('city'),
      value:
        (isAR
          ? warehousesSearch.city?.nameLocale
          : warehousesSearch.city?.name) || '-',
    },
    {
      label: t('sfdaLicensing'),
      value:
        warehousesSearch.sfda_license_type
          ?.map((type) =>
            t(`warehouseLicenses.${type.value ?? 'noSFDALicense'}`)
          )
          .join(', ') || t('noSFDALicense'),
    },
    {
      label: t('warehouseType'),
      value:
        warehousesSearch.warehouse_type
          ?.map(
            (type) =>
              warehouseTypeOptions.find((option) => option.value === type.value)
                ?.label
          )
          .join(', ') || '-',
    },
    {
      label: t('quantity'),
      value: quantity ? `${quantity} ${t(quantityType)}` : '-',
    },
    {
      label: t('startDate'),
      value: formatDate(warehousesSearch.date, 'dd-MM-yyyy') ?? '-',
    },
  ];

  const handleCloseModal = () => {
    setSelectedWarehouse(null);
    onPreviewModalClose();
  };

  const setLocationState = (data) => {
    navigate('/warehouses/search', {
      state: { warehousesSearch: data },
      replace: true,
    });
  };

  const handleSearchSubmit = (data) => {
    const city = queryClient
      .getQueriesData(['quote-cities'])[0]?.[1]
      ?.find((quoteCity) => quoteCity.id === data.city_id);

    const dataWithCity = { ...data, city };

    handleQuoteSearch(dataWithCity);
    setWarehousesSearch(dataWithCity);
    setLocationState(dataWithCity);
    onSearchFormClose();
  };

  return (
    <>
      <Stack spacing={6}>
        <Flex>
          <BackToListButton
            title={t('back')}
            onClick={() => navigate('/warehouses')}
          />
        </Flex>
        <Stack
          spacing={2}
          background="white"
          borderRadius="xl"
          padding={{
            md: 6,
            base: 4,
          }}
        >
          <SectionTitle title={t('allWarehouses')} hideDivider />
          <HStack paddingY={4} paddingX={0} wrap="wrap" gap={3}>
            <Flex
              flex={1}
              alignItems={{ base: 'flex-start', md: 'center' }}
              gap={2}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Text
                fontWeight={500}
                fontSize="sm"
                color="gray.700"
                textTransform="capitalize"
              >
                {t('appliedFilters')}:
              </Text>
              <HStack wrap="wrap" spacing={2} flex={1}>
                {warehouseSearchSections.map(({ label, value }, index) => (
                  <Tag
                    key={index}
                    size="lg"
                    borderRadius="lg"
                    fontSize="xs"
                    variant="blue"
                    fontWeight={600}
                  >
                    {value}
                  </Tag>
                ))}
              </HStack>
            </Flex>
            <Button
              leftIcon={<FilterIcon />}
              bg="primary.100"
              color="primary.500"
              px={6}
              onClick={onSearchFormOpen}
              textTransform="capitalize"
              size="lg"
              width={{ base: 'full', md: 'auto' }}
            >
              {t('editSearch')}
            </Button>
          </HStack>

          {isRequestQuoteLoading ? (
            <Spinner color="primary.500" size="lg" />
          ) : noWarehousesFound ? (
            <EmptyTable
              illustration={<WarehouseEmptyTable />}
              title={t('noWarehousesFound')}
              subtitle={t('pleaseEditSearchCriteriaAndTryAgain')}
            />
          ) : (
            <Grid templateColumns="repeat(6, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 6, md: 2 }}>
                <Stack
                  bg="gray.50"
                  maxHeight={{ base: 'full', md: '1350px' }}
                  height="100%"
                  overflowY="auto"
                  borderRadius="2xl"
                  padding={4}
                  spacing={5}
                  divider={
                    <StackDivider borderColor="gray.300" borderWidth="2px" />
                  }
                >
                  {warehouses?.map((warehouse) => (
                    <SuggestedWarehousesDesktopItem
                      key={warehouse.id}
                      warehouse={warehouse}
                      warehousesSearch={warehousesSearch}
                      isSelected={selectedWarehouse?.id === warehouse.id}
                      onClick={() => {
                        if (
                          !isMobile &&
                          selectedWarehouse?.id === warehouse.id
                        ) {
                          return;
                        }

                        setSelectedWarehouse(warehouse);
                        onPreviewModalOpen();
                      }}
                    />
                  ))}
                </Stack>
              </GridItem>

              {isMobile && selectedWarehouse ? (
                <Modal
                  isOpen={isPreviewModalOpen}
                  onClose={handleCloseModal}
                  size="full"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader
                      position="sticky"
                      top={0}
                      bg="white"
                      zIndex={2}
                      paddingY={6}
                      display="flex"
                      paddingInlineEnd={14}
                    >
                      <SectionTitle
                        title={
                          selectedWarehouse.alias_name ?? selectedWarehouse.name
                        }
                        hideDivider
                      />
                      <ModalCloseButton marginTop={2} size="lg" />
                    </ModalHeader>
                    <ModalBody paddingBottom={4}>
                      <WarehousePreview
                        warehouse={selectedWarehouse}
                        isInitQuoteLoading={isInitQuoteLoading}
                        onBookACall={logBookACall}
                      />
                    </ModalBody>
                    <ModalFooter
                      borderTopWidth={1}
                      padding={4}
                      gap={4}
                      position="sticky"
                      bottom={0}
                      bg="white"
                    >
                      <Stack width="full" spacing={4}>
                        <Button
                          colorScheme="primary"
                          size="lg"
                          width="full"
                          isLoading={isInitQuoteLoading}
                          onClick={handleInitQuote}
                        >
                          {t('getInstantQuote')}
                        </Button>

                        <Button
                          variant="outline"
                          size="lg"
                          width="full"
                          onClick={handleCloseModal}
                        >
                          {t('cancel')}
                        </Button>
                      </Stack>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              ) : (
                <GridItem colSpan={4}>
                  {selectedWarehouse && (
                    <WarehousePreview
                      warehouse={selectedWarehouse}
                      onQuoteInit={handleInitQuote}
                      isInitQuoteLoading={isInitQuoteLoading}
                      onBookACall={logBookACall}
                    />
                  )}
                </GridItem>
              )}
            </Grid>
          )}
        </Stack>
      </Stack>

      <QuoteFormDrawer
        defaultValues={warehousesSearch}
        onSubmit={handleSearchSubmit}
        isOpen={isSearchFormOpen}
        onClose={onSearchFormClose}
      />
    </>
  );
};

export default SuggestedWarehouses;
