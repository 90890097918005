export const isNumber = (value) => typeof value === 'number';

export const formatCentsToSAR = (valueInCents) => {
  if (!isNumber(valueInCents)) {
    return valueInCents;
  }

  return (Math.floor(valueInCents) / 100).toLocaleString('en', {
    maximumFractionDigits: 2,
  });
};
