import { Select } from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';

const LanguageSelect = ({ size = 'md', ...props }) => {
  const { language, changeLanguage } = useLanguage();
  const handleChange = (e) => {
    const newLang = e.target.value;
    changeLanguage(newLang);
  };
  return (
    <Select
      value={language}
      onChange={handleChange}
      size={size}
      borderRadius="md"
      sx={{
        cursor: 'pointer',
        borderColor: 'gray.50',
        fontWeight: 600,
        paddingBlock: '0 !important',
        lineHeight: 1.5,
        color: 'gray.700',
        backgroundColor: 'gray.100',
      }}
      _hover={{
        backgroundColor: 'gray.200',
      }}
      {...props}
    >
      <option value="ar">العربية</option>
      <option value="en">English</option>
    </Select>
  );
};

export default LanguageSelect;
