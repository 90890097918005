import { Box, Flex, useMediaQuery, useRadio } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const RadioCard = ({
  children,
  register,
  required,
  withCheckMark = false,
  ...rest
}) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });
  const { getInputProps, getRadioProps } = useRadio(rest);

  const inputProps = getInputProps();
  const checkboxProps = getRadioProps();

  const formProps = register(inputProps.name, {
    required,
  });

  const isChecked = inputProps.checked;

  return (
    <Box as="label">
      <input
        {...inputProps}
        {...formProps}
        onChange={(e) => {
          inputProps.onChange(e);
          formProps.onChange(e);
        }}
      />
      <Flex
        {...checkboxProps}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="lg"
        _checked={{
          color: 'primary.500',
          borderColor: 'primary.500',
          boxShadow: '0 0 0 1px inset var(--chakra-colors-primary-100)',
          outline: '1px solid var(--chakra-colors-primary-500)',
          backgroundColor: withCheckMark ? 'primary.50' : undefined,
        }}
        style={{
          height: '100%',
          alignItems: 'flex-start',
        }}
        px={3}
        py={2.5}
        color="gray.700"
        fontWeight={500}
        alignItems="center"
        justifyContent={{ base: 'start', md: 'center' }}
        columnGap={1}
      >
        {withCheckMark && !isMobile && (
          <Flex marginTop={5} alignItems="start">
            {isChecked ? (
              <CheckCircleIcon width="16px" height="16px" />
            ) : (
              <Box
                sx={{
                  width: '16px',
                  height: '16px',
                  border: '1px solid',
                  borderColor: 'gray.400',
                }}
                rounded="full"
              />
            )}
          </Flex>
        )}
        {children}
      </Flex>
    </Box>
  );
};
export default RadioCard;
