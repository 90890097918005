import { useState, useEffect, useCallback, useMemo } from 'react';

const VAPID_PUBLIC_KEY = ''; // TODO: add key value

const checkSupport = () => {
  const checks = {
    notification: 'Notification' in window,
    serviceWorker: 'serviceWorker' in navigator,
    pushManager: 'PushManager' in window,
  };

  if (!checks.notification) {
    return {
      supported: false,
      error: 'Push notifications are not supported on this device',
    };
  }
  if (!checks.serviceWorker) {
    return { supported: false, error: 'Service Worker is not supported' };
  }
  if (!checks.pushManager) {
    return { supported: false, error: 'Push notifications are not supported' };
  }

  return { supported: true, error: null };
};

const usePushNotifications = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { supported: isSupported, error: supportError } = useMemo(
    () => checkSupport(),
    []
  );

  // Check for existing subscription
  useEffect(() => {
    if (!isSupported) {
      setError(supportError);
      return;
    }

    const updateSubscriptionStatus = async () => {
      try {
        const registration = await navigator.serviceWorker.ready;
        const existingSubscription =
          await registration.pushManager.getSubscription();

        setSubscription(existingSubscription);
      } catch (err) {
        setError(err.message);
      }
    };

    updateSubscriptionStatus();
  }, [isSupported, supportError]);

  const requestPermission = useCallback(async () => {
    if (!isSupported) {
      return 'denied';
    }

    try {
      setLoading(true);

      const result = await Notification.requestPermission();
      return result;
    } catch (err) {
      setError(err.message);
      return 'denied';
    } finally {
      setLoading(false);
    }
  }, [isSupported]);

  const subscribe = useCallback(
    async (vapidPublicKey = VAPID_PUBLIC_KEY) => {
      if (!isSupported) {
        throw new Error('Push notifications are not supported on this device');
      }

      if (!vapidPublicKey) {
        throw new Error('VAPID public key is required');
      }

      try {
        setLoading(true);
        setError(null);

        if (Notification.permission !== 'granted') {
          const permissionResult = await requestPermission();

          if (permissionResult !== 'granted') {
            throw new Error('Permission not granted for notifications');
          }
        }

        const registration = await navigator.serviceWorker.ready;
        const pushSubscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey,
        });

        setSubscription(pushSubscription);
        return pushSubscription;
      } catch (err) {
        setError(err.message);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [isSupported, requestPermission]
  );

  const unsubscribe = useCallback(async () => {
    if (!subscription) return;

    try {
      setLoading(true);
      setError(null);

      await subscription.unsubscribe();

      setSubscription(null);
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [subscription]);

  return {
    subscribe,
    unsubscribe,
    loading,
    error,
  };
};

export default usePushNotifications;
