import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  getNotification,
  getNotifications,
  getUnreadNotifications,
} from 'api/Dashboard/notifications';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import useLanguage from 'hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { formatDate, formatDateDistance } from 'utils/date';

const NotificationsTab = ({ children }) => (
  <Tab
    borderRadius="lg"
    fontSize="sm"
    fontWeight={600}
    _selected={{ color: 'primary.500', bg: 'primary.100' }}
    background="gray.200"
    color="gray.500"
    textTransform="capitalize"
  >
    {children}
  </Tab>
);

const getURL = (notification) => {
  const {
    message: { action, entity, entity_id },
    record: { record_type, record_id },
  } = notification;

  if (action === 'comment') {
    return `${entity === 'Inbound' ? 'inbounds' : 'outbounds'}/${entity_id}`;
  }

  if (action === 'low_inventory') {
    return `skus/${record_id}`;
  }

  if (action === 'near_to_expiry_inventory' || action === 'expired_inventory') {
    return 'outbounds/create';
  }

  return `${
    record_type === 'Manifest' ? 'inbounds' : 'outbounds'
  }/${record_id}`;
};

const Notification = ({ notification, onClose }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const {
    created_at,
    message: { creator, identifier, entity, action },
    read_at,
  } = notification;

  const url = getURL(notification);

  // getNotification marks the notification as read
  const { refetch: fetchNotificationDetails } = useQuery(
    ['notification-details', notification.id],
    () => getNotification(notification.id),
    {
      enabled: false,
    }
  );

  const handleClick = () => {
    onClose();
    if (!read_at) {
      fetchNotificationDetails();
    }
  };

  const actionMessage = () => {
    switch (action) {
      case 'create':
        return t('notification.created');
      case 'complete':
        return t('notification.completed');
      case 'draft':
        return t('notification.submittedNewDraft', {
          value: t(entity),
        });
      case 'low_inventory':
        return t('notification.lowInventory');
      case 'comment':
        return t('notification.commentedOn');
      case 'near_to_expiry_inventory':
        return `${t('notification.near_to_expiry_inventory')}, ${t(
          'notification.clickToCreateOutbound'
        )}`;

      case 'expired_inventory':
        return `${t('notification.expired_inventory')}, ${t(
          'notification.clickToCreateOutbound'
        )}`;

      case 'cancel':
        return t('notification.cancelled');

      default:
        return '';
    }
  };

  const content = (
    <Text fontSize="xs" color="gray.600" fontWeight={500}>
      {identifier && (
        <Text fontWeight={600} color="gray.800" as="span">
          {`#${identifier} `}
        </Text>
      )}
      <Text as="span" textTransform="capitalize">
        {actionMessage()}
      </Text>{' '}
      {action !== 'draft' && t(entity)}
    </Text>
  );

  return (
    <Flex
      bg={!read_at && 'gray.50'}
      borderRadius="xl"
      p={6}
      gap={6}
      as={RouterLink}
      to={url}
      onClick={handleClick}
      _hover={{ opacity: 0.85 }}
      textAlign="start"
    >
      <Box>
        <Avatar name={creator?.name} src={creator?.avatar} boxSize="48px" />
      </Box>

      <Flex flexFlow="column" gap={1} flex={1}>
        <Flex justifyContent="space-between">
          <Text fontSize="sm" fontWeight={600}>
            {creator?.name}
          </Text>
          <Text
            fontSize="xs"
            color="gray.600"
            fontWeight={500}
            whiteSpace="nowrap"
          >
            {formatDate(created_at, 'hh:mm a')}
          </Text>
        </Flex>
        <Box paddingInlineEnd={6} position="relative">
          {content}

          {!read_at && (
            <Text
              as="span"
              bg="primary.500"
              borderRadius="full"
              style={{
                width: '10px',
                height: '10px',
                display: 'inline-block',
                position: 'absolute',
                top: 0,
                right: isRTL ? 'auto' : 0,
                left: isRTL ? 0 : 'auto',
                zIndex: 1,
              }}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

const groupNotifications = (notifications) =>
  notifications.reduce((result, item) => {
    const createdAtDate = item.created_at.split('T')[0]; // Extracting date part
    if (!result[createdAtDate]) {
      result[createdAtDate] = [];
    }
    result[createdAtDate].push(item);
    return result;
  }, {});

const NotificationsLayout = ({ notifications, onClose, isLoading }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Spinner color="primary.500" />;
  }

  if (notifications?.length === 0) {
    return (
      <Text fontSize="sm" color="gray.600" textAlign="center">
        {t('notification.noNotifications')}
      </Text>
    );
  }

  const groupedNotifications = groupNotifications(notifications);

  return (
    <Stack spacing={6}>
      {Object.keys(groupedNotifications).map((date) => (
        <Stack key={date} spacing={4}>
          <Box>
            <Text fontSize="xs" fontWeight={600} color="gray.600">
              {formatDateDistance(date)}
            </Text>
          </Box>

          <Stack spacing={3}>
            {groupedNotifications[date].map((notification, index) => (
              <Notification
                key={notification.id}
                notification={notification}
                onClose={onClose}
              />
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

const AllNotifications = ({ onClose }) => {
  const { data: notifications = [], isLoading } = useQuery(
    'notifications',
    getNotifications,
    {
      refetchOnMount: true,
    }
  );

  return (
    <NotificationsLayout
      notifications={notifications}
      onClose={onClose}
      isLoading={isLoading}
    />
  );
};

const UnreadNotifications = ({ onClose }) => {
  const { data: notifications = [], isLoading } = useQuery(
    'unread-notifications',
    getUnreadNotifications,
    {
      refetchOnMount: true,
    }
  );

  return (
    <NotificationsLayout
      notifications={notifications}
      onClose={onClose}
      isLoading={isLoading}
    />
  );
};

const Notifications = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      offset={[isRTL ? 150 : -150, 23]}
      isLazy
    >
      <PopoverTrigger>
        <IconButton
          onClick={onToggle}
          size="lg"
          borderRadius="full"
          icon={<BellIcon />}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          borderRadius="xl"
          minWidth={{ base: 'auto', sm: '460px' }}
          padding={6}
        >
          <PopoverArrow border={0} boxShadow="none" />

          <PopoverHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            fontWeight={600}
            paddingX={0}
            paddingTop={0}
            paddingBottom={6}
            marginBottom={2}
          >
            <Text textTransform="capitalize">{t('notifications')}</Text>
            <PopoverCloseButton
              position="static"
              padding="0"
              onClick={onClose}
            />
          </PopoverHeader>
          <PopoverBody padding={0}>
            <Tabs variant="unstyled" isLazy>
              <TabList gap={2} paddingY={4}>
                <NotificationsTab>{t('allNotifications')}</NotificationsTab>
                <NotificationsTab>{t('unread')}</NotificationsTab>
              </TabList>
              <TabPanels maxHeight="50vh" overflowY="auto">
                <TabPanel padding={0}>
                  <AllNotifications onClose={onClose} />
                </TabPanel>
                <TabPanel paddingX={0}>
                  <UnreadNotifications onClose={onClose} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default Notifications;
