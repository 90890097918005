import RegistrationHeader from 'components/shared/Registration/RegistrationHeader';
import ImageOverlay from 'components/shared/Registration/ImageOverlay';
import sirdabLogo from 'assets/images/Sirdab_Logo-01.png';
import LanguageSelect from '../Inputs/LanguageSelect';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';

const RegistrationLayout = ({
  title,
  subtitle,
  content,
  imageTitle,
  imageContent,
  showLogoutButton = false,
}) => {
  const { t } = useTranslation();
  const { signOut } = useAuth();

  return (
    <div className="flex min-h-full flex-1 p-6">
      <div className="flex flex-1 flex-col justify-between sm:px-6 lg:flex-none lg:px-20 xl:px-24 min-w-[60%]">
        <div className="w-full flex justify-between lg:ltr:pr-6 lg:rtl:pl-6">
          <img className="h-8 w-auto mb-4" src={sirdabLogo} alt="Sirdab logo" />
          <div className="flex items-center gap-2">
            <LanguageSelect size="md" background="gray.100" border="none" />
            {showLogoutButton && (
              <Button
                colorScheme="primary"
                onClick={() => signOut()}
                minWidth="100px"
              >
                {t('logout')}
              </Button>
            )}
          </div>
        </div>
        <div className="mx-auto w-full max-w-md lg:w-120">
          <RegistrationHeader title={title} subtitle={subtitle} />
          <div className="mt-6">{content}</div>
        </div>
        <div></div>
      </div>
      <ImageOverlay title={imageTitle} content={imageContent} />
    </div>
  );
};

export default RegistrationLayout;
