import { createContext, useState, useContext } from 'react';

const PaymentDueContext = createContext();

export const PAYMENT_DUE_DAYS_LIMIT = {
  INVOICE_ALERT: 2,
  INVOICE_MODAL: 28,
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
};

export function PaymentDueProvider({ children }) {
  const [paymentDueDays, setPaymentDueDays] = useState();

  const isPaymentDueForAlert =
    paymentDueDays >= PAYMENT_DUE_DAYS_LIMIT.INVOICE_ALERT;

  const isPaymentDueForModal =
    paymentDueDays >= PAYMENT_DUE_DAYS_LIMIT.INVOICE_MODAL;

  const isPaymentDueForAccountLocked =
    paymentDueDays === PAYMENT_DUE_DAYS_LIMIT.ACCOUNT_LOCKED;

  return (
    <PaymentDueContext.Provider
      value={{
        paymentDueDays,
        setPaymentDueDays,
        isPaymentDueForAlert,
        isPaymentDueForModal,
        isPaymentDueForAccountLocked,
      }}
    >
      {children}
    </PaymentDueContext.Provider>
  );
}

export function usePaymentDue() {
  return useContext(PaymentDueContext);
}
