import { useTranslation } from 'react-i18next';
import PageDrawer from '../PageDrawer';
import QuoteForm from './QuoteForm';
import { logEvent } from 'utils/amplitude';
import { mapToRequestQuotePayload } from './SuggestedWarehouses';

const QuoteFormDrawer = ({ defaultValues, onSubmit, isOpen, onClose }) => {
  const { t } = useTranslation();
  const hasDefaultValues = Object.keys(defaultValues ?? {}).length > 0;

  return (
    <PageDrawer
      title={t('searchForSpecificWarehouse')}
      isOpen={isOpen}
      onClose={onClose}
      formId="quoteForm"
      submitText="Search"
    >
      <QuoteForm
        onSubmit={(data) => {
          onSubmit(data);

          const payload = mapToRequestQuotePayload(data);

          if (payload.city) {
            delete payload.city;
          }

          if (hasDefaultValues) {
            logEvent('warehouses_editSearchClicked', {
              ...payload,
            });
          } else {
            logEvent('warehouses_submitSearchClicked', {
              ...payload,
            });
          }
        }}
        defaultValues={defaultValues}
      />
    </PageDrawer>
  );
};

export default QuoteFormDrawer;
