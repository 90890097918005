import {
  Card,
  CardHeader,
  CardBody,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';
import { deleteAttachment, uploadAttachment } from 'api/Dashboard/attachments';
import { AttachmentUpload } from '../AttachmentUpload';

const AttachmentsSection = ({
  id,
  attachments = [],
  onSuccess,
  attachmentType,
  attachmentAlias = 'images',
  disclaimer,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const attachmentsDefaultValue = attachments.map((attachment) => ({
    name: attachment.filename,
    size: attachment.byte_size,
    url: attachment.url,
    id: attachment.id,
  }));

  const { mutate: uploadAttachmentMutation, isLoading: isUploadingAttachment } =
    useMutation(uploadAttachment, {
      onSuccess,
      onError: () => {
        toast({
          title: t('attachmentUploadFailed'),
          status: 'error',
          duration: 2000,
        });
      },
    });

  const { mutate: deleteAttachmentMutation, isLoading: isDeletingAttachment } =
    useMutation(deleteAttachment, {
      onSuccess,
      onError: () => {
        toast({
          title: t('attachmentDeleteFailed'),
          status: 'error',
          duration: 2000,
        });
      },
    });

  const handleFileUpload = (files) => {
    const filesPayload = files.map((file) => ({
      file_name: file.name,
      file_type: file.type,
      base64_file: file.base64.split(',')[1],
    }));

    uploadAttachmentMutation({
      attachable_id: id,
      files: filesPayload,
      attachable_type: attachmentType,
      attachment_alias: attachmentAlias,
    });
  };

  const handleDeleteAttachment = (attachmentId) => {
    deleteAttachmentMutation(attachmentId);
  };

  const disabled = isUploadingAttachment || isDeletingAttachment || isDisabled;

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={`${t('attachments')} (${attachments.length})`} />
      </CardHeader>
      <CardBody>
        {isUploadingAttachment && <Spinner color="primary.500" />}
        <AttachmentUpload
          onChange={handleFileUpload}
          defaultValue={attachmentsDefaultValue}
          onDelete={handleDeleteAttachment}
          disclaimer={disclaimer}
          isDisabled={disabled}
        />
      </CardBody>
    </Card>
  );
};

export default AttachmentsSection;
