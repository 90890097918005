import {
  Box,
  Stack,
  Show,
  Flex,
  Hide,
  Button,
  Grid,
  GridItem,
  useToast,
  useMediaQuery,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  Center,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  addToCart,
  cancelOutbound,
  getOutboundById,
  getOutboundSKUs,
  removeOutboundSKU,
  submitOutboundForReview,
  updateCartQuantity,
  updateOutbound,
} from 'api/Dashboard/outbounds';
import BackToListButton from 'components/Dashboard/BackToListButton';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import EmptyTable from 'components/Dashboard/EmptyTable';
import AttachmentsSection from 'components/Dashboard/Orders/AttachmentsSection';
import OutboundCartForm from './OutboundCartForm';
import TablePage from 'components/Dashboard/TablePage';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { AbilityContext } from 'context/AbilityContext';
import useTable from 'hooks/useTable';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import { handleNotFoundPage } from 'utils/notFoundPage';
import TransportationRate from 'components/Dashboard/Orders/TransportationRate';
import {
  createTransportationJourney,
  deleteTransportationJourney,
  updateTransportationOrder,
} from 'api/Dashboard/transportation';
import { showErrorToast } from 'utils/toast';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';

const columnHelper = createColumnHelper();

const OutboundAssign = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });
  const [editItemID, setEditItemID] = useState(undefined);

  const ability = useContext(AbilityContext);
  const canConfirm = ability.can('confirm', 'outbound');

  const fetchFn = useCallback(
    (searchParams) => getOutboundSKUs(id, searchParams),
    [id]
  );

  const {
    data: skus = [],
    isLoading,
    onSearchChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    refetch: refetchSKUs,
    meta,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['outbound-skus', id],
    searchKey: 'sku_merchant_sku_or_sku_system_sku_or_sku_name_cont',
    disableURLParams: true,
  });

  const { data: outboundDetails = {}, refetch: refetchOutboundDetails } =
    useQuery(['outbound-details', id], () => getOutboundById(id), {
      onError: handleNotFoundPage,
    });

  const { mutate: removeOutboundSKUMutation, isLoading: isRemoveSKULoading } =
    useMutation((skuID) => removeOutboundSKU({ id, skuID }), {
      onSuccess: (data) => {
        refetchSKUs();
        toast({
          title: t('skuRemovedFromCartSuccessfully'),
          status: 'success',
          duration: 5000,
        });
      },
    });

  const { mutate: confirmOutbound, isLoading: isConfirmOutboundLoading } =
    useMutation(
      async () => {
        await updateOutbound(id, {
          status: 'created',
        });
      },
      {
        onSuccess: () => {
          navigate(`/outbounds/${id}`);
        },
        onError: (error) => {
          const errorMsg = error.response.data.errors.status[0];

          toast({
            title: errorMsg,
            status: 'error',
            duration: 5000,
          });
        },
      }
    );

  const { mutate: submitOutbound, isLoading: isSubmitOutboundLoading } =
    useMutation(() => submitOutboundForReview(id), {
      onSuccess: () => {
        navigate(`/outbounds/${id}`);
      },
    });

  const transactionDetails = outboundDetails.transaction_details ?? {};
  const selectedCarrierId = transactionDetails.selected_carrier_service_id;

  const status = outboundDetails.status;
  const isDraft = status === 'draft';

  const showTransportationRate =
    outboundDetails.outbound_type === 'DELIVERY_SERVICE';

  const onSubmit = () => {
    if (skus.length === 0) {
      return showErrorToast('skuError');
    }

    if (showTransportationRate) {
      if (!selectedCarrierId) {
        return showErrorToast('pleaseSelectCarrier');
      }

      if (transactionDetails.trucks === 0) {
        return showErrorToast('pleaseEnterNumberOfTrucks');
      }
    }

    canConfirm ? confirmOutbound() : submitOutbound();
  };

  const { mutate: cancelOutboundMutation, isLoading: isCancelOutboundLoading } =
    useMutation(() => cancelOutbound(id), {
      onSuccess: (success) => {
        navigate(`/outbounds/${id}`);
      },
    });

  const {
    mutate: editOutboundSKUMutation,
    isLoading: isEditOutboundSKULoading,
  } = useMutation((payload) => updateCartQuantity(id, payload), {
    onSuccess: () => {
      refetchSKUs();
    },
    onError: (error) => {
      toast({
        title: error.response.data.errors[0],
        status: 'error',
        duration: 5000,
      });
    },
  });

  const handleEditOutboundSKU = (payload) => {
    editOutboundSKUMutation(payload);
  };

  const actionColumn = columnHelper.accessor('actions', {
    cell: ({ row }) => {
      const id = row.original.id;

      const handleDelete = () => {
        removeOutboundSKUMutation(row.original.id);
      };

      const handleEdit = () => {
        setEditItemID((prevValue) => (prevValue === id ? undefined : id));
      };

      if (isMobile) {
        return (
          <Menu>
            <MenuButton as={IconButton} icon={<DotsVerticalIcon />} size="sm" />
            <MenuList>
              <MenuItem textTransform="capitalize" onClick={handleEdit}>
                {t('edit')}
              </MenuItem>
              <MenuItem
                textTransform="capitalize"
                color="red.500"
                onClick={handleDelete}
              >
                {t('remove')}
              </MenuItem>
            </MenuList>
          </Menu>
        );
      }

      return (
        <fieldset disabled={isRemoveSKULoading ? 'disabled' : undefined}>
          <Flex gap={3} justifyContent="center">
            <Button
              variant="outline"
              color="gray.600"
              textTransform="capitalize"
              fontWeight={500}
              onClick={handleEdit}
            >
              {t('edit')}
            </Button>
            <Button
              variant="outline"
              color="red.600"
              textTransform="capitalize"
              fontWeight={500}
              onClick={handleDelete}
            >
              {t('remove')}
            </Button>
          </Flex>
        </fieldset>
      );
    },
    header: t('actions'),
    meta: {
      isAction: true,
      centerHeader: true,
    },
    enableSorting: false,
  });

  const columns = [
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => getValue(),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('sku_system_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('sku_merchant_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('merchantSKU'),
    }),
    columnHelper.accessor('requested.packaging', {
      cell: ({ getValue }) => <Center>{t(getValue())}</Center>,
      header: t('packaging'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('requested.quantity', {
      cell: ({ getValue, row }) => {
        const { id } = row.original;

        if (id === editItemID) {
          const defaultValue = getValue();

          return (
            <Center>
              <Box maxWidth="100px">
                <DebouncedNumberInput
                  placeholder="quantity"
                  name="quantity"
                  defaultValue={defaultValue}
                  min={1}
                  size="md"
                  onChange={(value) => {
                    handleEditOutboundSKU({
                      outbound_sku_id: id,
                      quantity: value,
                    });
                  }}
                  isDisabled={isEditOutboundSKULoading}
                />
              </Box>
            </Center>
          );
        }
        return <Center>{getValue()}</Center>;
      },
      header: t('quantity'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('boxes_per_pallet', {
      cell: ({ getValue }) => <Center>{getValue() || '-'}</Center>,
      header: t('casesPallets'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('units_per_box', {
      cell: ({ getValue }) => <Center>{getValue() || '-'}</Center>,
      header: t('unitsCase'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('batch_numbers', {
      cell: ({ getValue, row }) => {
        const values = getValue() ?? [];

        if (values.length === 0) {
          return '-';
        }

        return (
          <UnorderedList paddingLeft={1} display="flex" flexDirection="column">
            {values.map((value, index) => (
              <ListItem key={`${row.original.id}-batch-${index}`}>
                {value}
              </ListItem>
            ))}
          </UnorderedList>
        );
      },
      header: t('Batch/Lot Number'),
      enableSorting: false,
    }),
    ...(isDraft ? [actionColumn] : []),
  ];

  const {
    mutate: addToCartMutation,
    error: addToCartError,
    isLoading: isAddToCartLoading,
  } = useMutation((payload) => addToCart(id, payload), {
    onSuccess: () => {
      refetchSKUs();
      toast({
        title: t('skuAddedToCartSuccessfully'),
        status: 'success',
        duration: 5000,
      });
    },
  });

  const handleAddToCartSubmit = (data, onSuccess) => {
    const payload = {
      sku_id: data.sku_id,
      packaging: data.packaging,
      configurations: Object.keys(data.configurations).map((key) => {
        const quantity = data.configurations[key];
        const [boxes_per_pallet, units_per_box] = key.split('-');

        return {
          boxes_per_pallet: Number(boxes_per_pallet),
          units_per_box: Number(units_per_box),
          quantity: Number(quantity),
        };
      }),
    };

    addToCartMutation(payload, { onSuccess });
  };

  const {
    mutate: createTransportationJourneyMutation,
    isLoading: isCreateTransportationJourneyLoading,
  } = useMutation(createTransportationJourney, {
    onSuccess: () => refetchOutboundDetails(),
  });

  const {
    mutate: deleteTransportationJourneyMutation,
    isLoading: isDeleteTransportationJourneyLoading,
  } = useMutation(deleteTransportationJourney, {
    onSuccess: () => refetchOutboundDetails(),
  });

  const transportationOrderId = outboundDetails.transportation_order_id;

  const handleSelectCarrier = (carrierId) => {
    if (carrierId === null) {
      deleteTransportationJourneyMutation(transportationOrderId);
      return;
    }

    createTransportationJourneyMutation({
      carrier_service_id: carrierId,
      transportation_order_id: transportationOrderId,
    });
  };

  const { mutate: updateTransportationOrderMutation } = useMutation(
    (payload) => updateTransportationOrder(transportationOrderId, payload),
    {
      onSettled: () => refetchOutboundDetails(),
    }
  );

  const handleQuantitySubmit = (data) => {
    updateTransportationOrderMutation(data);
  };

  return (
    <Stack spacing={6} pb={16}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton
              title={t('outboundDetails')}
              url={`/outbounds/${id}/edit`}
            />
          </Hide>
        </Flex>
      </Box>
      <TablePage
        data={skus}
        columns={columns}
        pagination={pagination}
        title={
          <SectionTitle
            title={`${t('skuCart')} (${
              isLoading ? '...' : meta?.pagination?.total_count
            })`}
            hideDivider
          />
        }
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('skusPages.searchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
        footer={
          isDraft && (
            <OutboundCartForm
              onSubmit={handleAddToCartSubmit}
              outboundId={id}
              warehouseId={outboundDetails.warehouse_id}
              backendErrors={addToCartError?.response?.data?.errors}
              isWithinEmptyTable={skus.length === 0 && !isLoading}
              isSubmitting={isAddToCartLoading}
            />
          )
        }
        emptyTable={
          <EmptyTable
            illustration={<SKUsEmptyTable />}
            title={t('yourCartIsEmpty')}
            maxWidth="650px"
            noPadding
          />
        }
        showEmptyInsideBody
      />

      {showTransportationRate && (
        <TransportationRate
          transportationOrderId={transportationOrderId}
          onSelectCarrier={handleSelectCarrier}
          onQuantitySubmit={handleQuantitySubmit}
          isSelectCarrierLoading={
            isCreateTransportationJourneyLoading ||
            isDeleteTransportationJourneyLoading
          }
          location={{
            originId: outboundDetails.origin.id,
            destinationId: outboundDetails.destination.id,
          }}
          defaultValues={{
            selectedCarrierId,
            truck_size: transactionDetails.truck_size,
            truck_type: transactionDetails.truck_type,
            delivery_type: transactionDetails.delivery_type,
            trucks: transactionDetails.trucks,
            laborers: transactionDetails.laborers,
          }}
          isDisabled={!isDraft}
        />
      )}

      {!isLoading && (
        <AttachmentsSection
          id={id}
          attachments={outboundDetails.attachments}
          onSuccess={refetchOutboundDetails}
          attachmentType="Outbound"
          isDisabled={!isDraft}
        />
      )}
      <CreateButtonsContainer>
        <Box
          as="fieldset"
          disabled={
            isCancelOutboundLoading || isConfirmOutboundLoading
              ? 'disabled'
              : undefined
          }
          flex={1}
        >
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 3, md: 2 }}>
              <Button
                colorScheme="gray"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                width="full"
                as={Link}
                to={`/outbounds/${id}`}
              >
                {t('saveDraft')}
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 2 }}>
              <Button
                colorScheme="red"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                width="full"
                onClick={cancelOutboundMutation}
                isLoading={isCancelOutboundLoading}
              >
                {t('cancelOutbound')}
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 2 }}>
              <Button
                colorScheme="primary"
                size="lg"
                minWidth="124px"
                textTransform="capitalize"
                width="full"
                onClick={onSubmit}
                isLoading={
                  canConfirm
                    ? isConfirmOutboundLoading
                    : isSubmitOutboundLoading
                }
              >
                {t(canConfirm ? 'confirm' : 'submitForReview')}
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </CreateButtonsContainer>
    </Stack>
  );
};

export default OutboundAssign;
