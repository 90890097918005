import { PopupButton } from 'react-calendly';

const CalendlyPopupText = ({
  content,
  url = 'https://calendly.com/sirdab-naif',
}) => {
  return (
    <PopupButton
      url={url}
      rootElement={document.getElementById('root')}
      text={content}
    />
  );
};

export default CalendlyPopupText;
