import { useTranslation } from 'react-i18next';

import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import VerifyOTPForm from 'components/VerifyOTP/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { getUser } from 'api/auth';
import { useQuery } from 'react-query';
import { setLocalStorage } from 'utils/localStorage';
import { useToast } from '@chakra-ui/react';

const VerifyOTP = () => {
  const { confirmOTP, isConfirmOtpLoading, confirmOtpError } = useAuth();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const email = location.state?.email || t('yourEmail');

  const { refetch: getUserQuery } = useQuery('user-me', getUser, {
    onSuccess: (data) => {
      setLocalStorage('user', data);

      if (data?.otp_verified_at) {
        toast({
          title: t('yourEmailHasBeenVerified'),
          status: 'success',
          duration: 5000,
        });
        navigate('/');
      }
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const handleSubmit = ({ verificationCode }) => {
    confirmOTP(verificationCode, {
      onSuccess: () => getUserQuery(),
    });
  };

  return (
    <RegistrationLayout
      title={t('enterVerificationCode')}
      subtitle={`${t('weSentVerificationCodeTo')} ${email}`}
      content={
        <VerifyOTPForm
          onSubmit={handleSubmit}
          responseError={confirmOtpError}
          isLoading={isConfirmOtpLoading}
        />
      }
      showLogoutButton
    />
  );
};

export default VerifyOTP;
