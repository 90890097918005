import { Button, Box } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import useLanguage from 'hooks/useLanguage';

const WithAddNewButton = ({ children, onClick, isDisabled }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  return (
    <Box position="relative">
      {children}

      <Button
        colorScheme="primary"
        variant="ghost"
        size="sm"
        textTransform="capitalize"
        sx={{
          position: 'absolute',
          top: '-5px',
          right: isRTL ? 'auto' : 0,
          left: isRTL ? 0 : 'auto',
        }}
        onClick={onClick}
        isDisabled={isDisabled}
      >
        {t('addNew')}
      </Button>
    </Box>
  );
};

export default WithAddNewButton;
