import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Button,
  useDisclosure,
  Stack,
  Flex,
  Text,
  Skeleton,
  useToast,
} from '@chakra-ui/react';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useTranslation } from 'react-i18next';
import PageDrawer from '../PageDrawer';
import { useMutation, useQuery } from 'react-query';
import { getAddressById, updateAddress } from 'api/Dashboard/addresses';
import POCForm from '../POCForm';
import { getWarehouseById } from 'api/Dashboard/warehouses';

const POCDetailsItem = ({ label, value, isLoading }) => {
  return (
    <Stack spacing={2}>
      <Text fontWeight={500} color="gray.600">
        {label}
      </Text>
      <Skeleton isLoaded={!isLoading}>
        <Text fontWeight={600}>{value}</Text>
      </Skeleton>
    </Stack>
  );
};

const POCDetailsSection = ({ id, isWarehouse, isOutbound, isDisabled }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: isPOCOpen,
    onOpen: onPOCOpen,
    onClose: onPOCClose,
  } = useDisclosure();

  const {
    data: addressDetails,
    refetch: refetchAddressDetails,
    isLoading: isAddressDetailsLoading,
    isRefetching: isAddressDetailsRefetching,
  } = useQuery(['address-details', id], () => getAddressById(id), {
    enabled: !isWarehouse && !!id,
    cacheTime: 100,
  });

  const {
    data: warehouseDetails,
    refetch: refetchWarehouseDetails,
    isLoading: isWarehouseDetailsLoading,
    isRefetching: isWarehouseDetailsRefetching,
  } = useQuery(['warehouse-details', id], () => getWarehouseById(id), {
    enabled: !!isWarehouse && !!id,
    cacheTime: 100,
  });

  const addressPOC = addressDetails?.pocs?.find((poc) => poc.main) ?? {};
  const warehousePOC = warehouseDetails?.main_poc ?? {};
  const poc = isWarehouse ? warehousePOC : addressPOC;

  const { mutate: updatePOC, isLoading: isUpdatePOCLoading } = useMutation(
    updateAddress,
    {
      onSuccess: () => {
        toast({
          title: t('pocUpdateSuccess'),
          status: 'success',
          duration: 3000,
        });
        onPOCClose();
        isWarehouse ? refetchWarehouseDetails() : refetchAddressDetails();
      },
    }
  );

  const handleUpdatePOC = (data) => {
    updatePOC({
      pocs_attributes: [{ ...data, main: true }],
      id: id,
    });
  };

  const pocFormDefaultValues = {
    id: poc.id,
    name: poc.name,
    phone_number: poc.phone_number,
  };

  const pocDetailsItems = [
    {
      label: t('orderDetails.pocName'),
      value: poc.name ?? '-',
    },
    {
      label: t(
        `orderDetails.${isWarehouse ? 'warehousePOCPhone' : 'clientPOCPhone'}`
      ),
      value: poc.phone_number ?? '-',
    },
  ];

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle
          title={
            <Flex
              justifyContent="space-between"
              alignItems="center"
              width="full"
            >
              {t(
                isWarehouse
                  ? 'pocWarehouseDetails'
                  : isOutbound
                  ? 'pocDestinationDetails'
                  : 'pocOriginDetails'
              )}

              {!isWarehouse && (
                <Button
                  colorScheme="primary"
                  variant="ghost"
                  size="sm"
                  textTransform="capitalize"
                  onClick={onPOCOpen}
                  isDisabled={isDisabled}
                >
                  {t('edit')}
                </Button>
              )}
            </Flex>
          }
        />
      </CardHeader>
      <CardBody>
        <SimpleGrid columns={2} spacing={4}>
          {pocDetailsItems.map(({ label, value }, index) => (
            <POCDetailsItem
              key={index}
              label={label}
              value={value}
              isLoading={
                isWarehouse
                  ? isWarehouseDetailsLoading || isWarehouseDetailsRefetching
                  : isAddressDetailsLoading || isAddressDetailsRefetching
              }
            />
          ))}
        </SimpleGrid>
      </CardBody>
      <PageDrawer
        title={t('editPOCDetails')}
        isOpen={isPOCOpen}
        onClose={() => onPOCClose()}
        formId="editPOCForm"
        isLoading={isUpdatePOCLoading}
      >
        <POCForm
          defaultValues={pocFormDefaultValues}
          onSubmit={handleUpdatePOC}
        />
      </PageDrawer>
    </Card>
  );
};

export default POCDetailsSection;
