import { Box } from '@chakra-ui/react';
import { Circle, GoogleMap, Marker } from '@react-google-maps/api';
import useMapLoader from 'hooks/useMapLoader';
import { useMediaQuery } from '@chakra-ui/react';

const MapPreview = ({ location }) => {
  const { isLoaded } = useMapLoader();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  if (!isLoaded || !location) return null;

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: isMobile ? '250px' : '350px',
        borderRadius: '16px',
      }}
      center={location}
      zoom={11}
      options={{
        disableDefaultUI: true,
        zoomControl: false,
        gestureHandling: 'none',
      }}
      key={`${location.lat}-${location.lng}`}
    >
      <Box>
        <Marker
          position={location}
          icon={{
            path: 'M12 2C8.13 2 5 5.13 5 9c0 4.25 7 13 7 13s7-8.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            scale: 2,
            strokeWeight: 0,
            anchor: new window.google.maps.Point(12, 12),
          }}
        />

        <Circle
          center={location}
          radius={5000}
          options={{
            fillColor: '#1E3FAE',
            strokeWeight: 0,
            fillOpacity: 0.2,
          }}
        />
        <Circle
          center={location}
          radius={4000}
          options={{
            fillColor: '#1E3FAE',
            strokeColor: '#ffffff',
            strokeWeight: 3,
            fillOpacity: 0.9,
          }}
        />
      </Box>
    </GoogleMap>
  );
};

export default MapPreview;
