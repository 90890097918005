import { Box, Center, Stack, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';
import { createColumnHelper } from '@tanstack/react-table';
import Table from 'components/Dashboard/Table';
import CalendlyPopupText from 'components/shared/CalendlyPopupText';
import { formatCentsToSAR } from 'utils/number';

const columnHelper = createColumnHelper();

const storagePriceRanges = [
  { min: 1, max: 50 },
  { min: 51, max: 100 },
  { min: 101, max: 200 },
  { min: 201, max: 500 },
];

const PriceCell = ({ value }) => {
  const { t } = useTranslation();
  const price = formatCentsToSAR(value);

  return (
    <Center gap={1}>
      <Text fontWeight={500}>{price}</Text>
      <Text fontWeight={600}>{t('SAR')}</Text>
    </Center>
  );
};

const StoragePrice = ({ services, onBookACall }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  const data = services?.map((service) => {
    const priceData = {};

    storagePriceRanges.forEach((storageRange) => {
      const matchingRange = service.price_ranges.find(
        (range) =>
          range.min <= storageRange.max &&
          (range.max >= storageRange.min || range.max === undefined)
      );

      const rangeKey = `${storageRange.min}-${storageRange.max}`;
      priceData[rangeKey] = matchingRange
        ? matchingRange.price_in_cents
        : 'N/A';
    });

    return {
      name: isAr ? service.ar_name : service.name,
      ...priceData,
    };
  });

  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('1-50', {
      cell: ({ getValue }) => <PriceCell value={getValue()} />,
      header: '1-50',
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('51-100', {
      cell: ({ getValue }) => <PriceCell value={getValue()} />,
      header: '51-100',
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('101-200', {
      cell: ({ getValue }) => <PriceCell value={getValue()} />,
      header: '101-200',
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('201-500', {
      cell: ({ getValue }) => <PriceCell value={getValue()} />,
      header: '201-500',
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('+500', {
      cell: ({ getValue }) => (
        <CalendlyPopupText
          url="https://calendly.com/fahad-sw/sirdab-15min-call"
          content={
            <Center>
              <Button
                variant="link"
                textDecoration="underline"
                colorScheme="primary"
                fontWeight={700}
                as="div"
                onClick={onBookACall}
              >
                {t('bookACall')}
              </Button>
            </Center>
          }
        />
      ),
      header: '+500',
      meta: {
        centerHeader: true,
      },
    }),
  ];

  return (
    <Stack spacing={4}>
      <Text
        textTransform="capitalize"
        fontWeight={500}
        fontSize="lg"
        color="gray.800"
      >
        {t('storagePrice')}
      </Text>

      <Box maxHeight="230px" overflow="auto">
        <Table
          data={data ?? []}
          columns={columns}
          enableSorting={false}
          hideSearch
          hidePagination
        />
      </Box>
    </Stack>
  );
};

export default StoragePrice;
